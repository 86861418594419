import React, { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import Sidebar from "./components/Reusable/Sidebar";
import TopBar from "./components/Reusable/TopBar";
import { decodeToken } from "./services/constant";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";

const BranchRoute = ({ component: Component, verified, path, title, ...rest }) => {
    const auth = decodeToken()
    return (
        <Route
            {...rest}
            render={(props) =>
                !auth ? (
                    <Redirect to="/" />
                ) : auth?.type == 'branch' ?
                    (
                        <BranchComponent component={Component} title={title} auth={auth} />
                    )
                    : (
                        <Redirect to="/404" />
                    )
            }
        />
    );
};

export default BranchRoute;

const BranchComponent = ({ component: Component, title: title, auth }) => {
    const history = useHistory();
    let { branchName } = useParams()

    useEffect(() => {
        if (branchName !== auth.branchName) {
            history.push('/404')
        }
    }, [])
    return (
        <>
            <section className='h-screen w-full flex items-stretch'>
                <Sidebar />
                <div id="main" className='flex-grow overflow-y-auto bg-1' style={{ height: '100dvh' }}>
                    <TopBar title={title} />
                    <Component />
                </div>
            </section>
        </>
    )
}