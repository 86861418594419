import React from 'react'

function AdminCloud2() {
    return (
        <>

            <svg className='w-96 fixed -top-10 -right-10' viewBox="0 0 361 362" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="150.984" cy="-31.0547" r="140.5" transform="rotate(180 150.984 -31.0547)" fill="#5675D7" stroke="#3D559E" stroke-width="19" />
                <circle cx="300.988" cy="52.9922" r="140.5" transform="rotate(180 300.988 52.9922)" fill="#5675D7" stroke="#3D559E" stroke-width="19" />
                <circle cx="402.422" cy="211.492" r="140.5" transform="rotate(180 402.422 211.492)" fill="#5675D7" stroke="#3D559E" stroke-width="19" />
            </svg>


            <svg className='w-96 fixed -bottom-10 -left-10' viewBox="0 0 358 380" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="207.508" cy="392.764" r="140.5" fill="#5675D7" stroke="#3D559E" stroke-width="19" />
                <circle cx="57.5039" cy="308.717" r="140.5" fill="#5675D7" stroke="#3D559E" stroke-width="19" />
                <circle cx="-43.9297" cy="150.221" r="140.5" fill="#5675D7" stroke="#3D559E" stroke-width="19" />
            </svg>

        </>

    )
}

export default AdminCloud2