import moment from 'moment/moment';
import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query';
import { BranchService } from '../../services/branch.service';
import { formattedDifference, week } from '../../services/constant';


function daysInMonth(month, year) {
    return new Date(year, month, 0).getDate();
}
const today = new Date()
const month = today.getMonth();
const date = today.getDate();
const year = today.getFullYear();
const YY = today.getFullYear() % 2000;

function SectorVisit({ employeeId }) {
    const [details, setDetails] = useState(false);
    const [todayActivity, setTodayActivity] = useState({
        first: '-',
        last: '-',
        sectors: 0
    })

    let { data: activityData, refetch } = useQuery('employeeSectorVisit', () => BranchService.employeeSectorVisit(employeeId), {
        refetchOnWindowFocus: false,
        retry: false,
        onSuccess: res => {
            if (res.data) {
                let act = res.data[moment(`${month + 1}/${date}/${year}`).format("DD/MM/YY")]
                let sectors = [];
                if (act?.length) {
                    act.forEach(element => {
                        if (!sectors.includes(element.sector)) {
                            sectors.push(element.sector)
                        }
                    });
                    let item = act?.reverse().find(el => el.exit);
                    setTodayActivity({
                        first: act[0].entry ? moment(act[0].entry).format('h:mm A') : '-',
                        last: item?.exit ? moment(item.exit).format('h:mm A') : '-',
                        sectors: sectors?.length || 0
                    })
                }
            }
        }
    })

    return (
        <section className='p-4'>
            {
                details
                    ?
                    <>
                        <svg onClick={() => setDetails(false)} width="90" height="23" className='mb-2 cursor-pointer' viewBox="0 0 90 23" fill="none" xmlns="http://www.w3.org/2000/svg" >
                            <path d="M88.0127 9.9082C88.8411 9.9082 89.5127 10.5798 89.5127 11.4082C89.5127 12.2366 88.8411 12.9082 88.0127 12.9082V9.9082ZM1.25131 12.4689C0.665527 11.8831 0.665527 10.9333 1.25131 10.3475L10.7973 0.801601C11.383 0.215815 12.3328 0.215815 12.9186 0.801601C13.5044 1.38739 13.5044 2.33714 12.9186 2.92292L4.4333 11.4082L12.9186 19.8935C13.5044 20.4793 13.5044 21.429 12.9186 22.0148C12.3328 22.6006 11.383 22.6006 10.7973 22.0148L1.25131 12.4689ZM88.0127 12.9082L2.31197 12.9082V9.9082L88.0127 9.9082V12.9082Z" fill="white" />
                        </svg >

                        <table className='table-auto w-full' style={{ borderCollapse: 'separate', borderSpacing: '0 4px' }}>
                            <thead className='rounded-lg'>
                                <tr>
                                    <td className='px-2 py-3 bg-4 rounded-tl-lg'>Date</td>
                                    <td className='px-2 py-3 bg-4'>Location</td>
                                    <td className='px-2 py-3 bg-4'>
                                        <div className='flex items-center min-w-max gap-32'>
                                            <h1>Enter time</h1>
                                            <h1>Exit time</h1>
                                        </div>
                                    </td>
                                    <td className='px-2 py-3 bg-4 text-center'>
                                        Entry type
                                    </td>
                                    <td className='px-2 py-3 bg-4 rounded-tr-lg'>No. of Attempts</td>
                                </tr>
                            </thead>
                            <tbody className='relative'>
                                {
                                    details?.length ?
                                        details?.map((item, i) => {
                                            const duration = moment.duration(moment(item.exit).diff(item.entry));
                                            const hours = Math.floor(duration.asHours());
                                            const minutes = duration.minutes();
                                            return (<tr key={i} className='text-sm'>
                                                <td className='p-2 bg-gray rounded-l-lg'>
                                                    <p>{item?.date}</p>
                                                    <p>{week[moment(`${item?.createdAt}`).day()]}</p>
                                                </td>
                                                <td className='p-2 bg-gray'>{item.sector}</td>
                                                <td className='p-2 bg-gray'>
                                                    <div className='flex items-center min-w-max gap-1'>
                                                        <h1>{moment(item.entry).format('h:mm A')}</h1>
                                                        <svg width="30" height="6" viewBox="0 0 30 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M0.833333 3.11328C0.833333 4.58604 2.02724 5.77995 3.5 5.77995C4.97276 5.77995 6.16667 4.58604 6.16667 3.11328C6.16667 1.64052 4.97276 0.446615 3.5 0.446615C2.02724 0.446615 0.833333 1.64052 0.833333 3.11328ZM29.3319 3.61328C29.608 3.61328 29.8319 3.38942 29.8319 3.11328C29.8319 2.83714 29.608 2.61328 29.3319 2.61328V3.61328ZM3.5 3.61328H29.3319V2.61328H3.5V3.61328Z" fill="white" />
                                                        </svg>
                                                        {
                                                            item?.status == 'pending'
                                                                ?
                                                                <p className='text-sm text-yellow'>Pending</p>
                                                                :
                                                                <>
                                                                    <p className='text-xs'>{formattedDifference(hours, minutes)}</p>
                                                                    <svg width="30" height="6" viewBox="0 0 30 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M1.33203 2.61328C1.05589 2.61328 0.832031 2.83714 0.832031 3.11328C0.832031 3.38942 1.05589 3.61328 1.33203 3.61328V2.61328ZM24.4972 3.11328C24.4972 4.58604 25.6911 5.77995 27.1639 5.77995C28.6367 5.77995 29.8306 4.58604 29.8306 3.11328C29.8306 1.64052 28.6367 0.446615 27.1639 0.446615C25.6911 0.446615 24.4972 1.64052 24.4972 3.11328ZM1.33203 3.61328H27.1639V2.61328H1.33203V3.61328Z" fill="white" />
                                                                    </svg>
                                                                    <h1>{moment(item.exit).format('h:mm A')}</h1>
                                                                </>
                                                        }
                                                    </div>
                                                </td>
                                                <td className='p-2 bg-gray capitalize text-center'>
                                                    {item.assisted ? <><p>{item?.assisted?.name}</p> <p className='text-xs italic ml-16 font-extralight'>Assisted</p></> : <span>Self</span>}
                                                </td>
                                                <td className='p-2 bg-gray rounded-r-lg'>
                                                    <p>{item.attempt || 0} Self</p>
                                                    <p>{item.assisted ? 1 : 0} Assisted</p>
                                                </td>
                                            </tr>)
                                        })
                                        :
                                        <h1 className='text-3xl font-medium min-w-max text-center absolute w-full mt-10'>No Activity</h1>
                                }
                            </tbody>
                        </table>
                    </>
                    :
                    <>
                        <h1 className='text-2xl font-medium'>Month Activity</h1>
                        <div className='flex flex-wrap gap-4 py-2'>
                            <div className={`w-48 h-48 bg-3 rounded-xl px-3 py-4`}>
                                <div className='w-full h-full flex flex-col justify-between cursor-pointer' onClick={() => setDetails(activityData?.data[moment(`${month + 1}/${date}/${year}`).format("DD/MM/YY")] || [])}>
                                    <div>
                                        <p>{moment(`${month + 1}/${date}/${year}`).format("DD/MM/YY")}</p>
                                        <p>{week[moment(`${month + 1}/${date}/${year}`).day()]}</p>
                                    </div>

                                    <div className='flex items-center justify-between'>
                                        <div>
                                            <h1 className='font-medium'>{todayActivity?.first}</h1>
                                            <p className='text-sm'>First entry</p>
                                        </div>
                                        <div className='text-right'>
                                            <h1 className='font-medium'>{todayActivity?.last}</h1>
                                            <p className='text-sm'>Latest exit</p>
                                        </div>
                                    </div>

                                    <div className='text-center'>
                                        <h1 className='font-medium'>{todayActivity?.sectors}</h1>
                                        <p className='text-sm'>Sectors Visited</p>
                                    </div>
                                </div>
                            </div>
                            <div className='w-48 h-48'>

                            </div>
                            {
                                Array(daysInMonth(month, year)).fill(1).map((item, i) => {
                                    let obj = {}
                                    let past = moment(`${month + 1}/${i + 1}/${year}`).diff(moment(), 'hours') < 1 || moment(`${month + 1}/${i + 1}/${year}`).format("DD/MM/YY") == moment().format("DD/MM/YY")

                                    if (past) {

                                        let act = activityData?.data[moment(`${month + 1}/${i + 1}/${year}`).format("DD/MM/YY")]
                                        let sectors = [];
                                        if (act?.length) {
                                            act.forEach(element => {
                                                if (!sectors.includes(element.sector)) {
                                                    sectors.push(element.sector)
                                                }
                                            });
                                            let item = act?.reverse().find(el => el.exit);
                                            obj = {
                                                first: act[0].entry ? moment(act[0].entry).format('h:mm A') : '-',
                                                last: item?.exit ? moment(item.exit).format('h:mm A') : '-',
                                                sectors: sectors?.length || 0
                                            }
                                        }
                                    }

                                    return (
                                        <div key={i} className={`w-48 h-48 ${moment(`${month + 1}/${i + 1}/${year}`).format("DD/MM/YY") == moment().format("DD/MM/YY") ? 'bg-3' : moment(`${month + 1}/${i + 1}/${year}`).diff(moment(), 'days') < 0 ? 'bg-2' : 'bg-black'} rounded-xl px-3 py-4`}>
                                            {
                                                past
                                                    ?
                                                    <div className='w-full h-full flex flex-col justify-between cursor-pointer' onClick={() => setDetails(activityData?.data[moment(`${month + 1}/${i + 1}/${year}`).format("DD/MM/YY")] || [])}>
                                                        <div>
                                                            <p>{moment(`${month + 1}/${i + 1}/${year}`).format("DD/MM/YY")}</p>
                                                            <p>{week[moment(`${month + 1}/${i + 1}/${year}`).day()]}</p>
                                                        </div>

                                                        <div className='flex items-center justify-between'>
                                                            <div>
                                                                <h1 className='font-medium'>{obj?.first || '-'}</h1>
                                                                <p className='text-sm'>First entry</p>
                                                            </div>
                                                            <div className='text-right'>
                                                                <h1 className='font-medium'>{obj?.last || '-'}</h1>
                                                                <p className='text-sm'>Latest exit</p>
                                                            </div>
                                                        </div>

                                                        <div className='text-center'>
                                                            <h1 className='font-medium'>{obj?.sectors || 0}</h1>
                                                            <p className='text-sm'>Sectors Visited</p>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className='w-full h-full flex flex-col items-center justify-center cursor-not-allowed'>
                                                        <p>{moment(`${month + 1}/${i + 1}/${year}`).format("DD/MM/YY")}</p>
                                                        <p>{week[moment(`${month + 1}/${i + 1}/${year}`).day()]}</p>
                                                    </div>
                                            }
                                        </div>

                                    )
                                }
                                )
                            }
                        </div>
                    </>
            }
        </section>
    )
}

export default SectorVisit