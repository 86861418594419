import moment from 'moment/moment';
import React from 'react'
import { useQuery } from 'react-query';
import { BranchService } from '../../services/branch.service';
import { groupBy } from '../../services/constant';
import MiniLoader from '../Reusable/MiniLoader';


function DeniedAccess({ employeeId }) {
  const { data: Insight, isFetching } = useQuery('deniedAccess', () => BranchService.deniedAccess(employeeId), {
    retry: false,
    refetchOnWindowFocus: false,
  })

  return (
    <div className='p-4 relative'>
      <p className='text-lg italic'>Showing latest 100 requests</p>
      {isFetching && <div className='w-full absolute top-32'>
        <MiniLoader />
      </div>}
      <table className='table-auto w-full' style={{ borderCollapse: 'separate', borderSpacing: '0 4px' }}>
        <thead className='rounded-lg'>
          <tr>
            <td className='px-2 py-3 bg-4 rounded-tl-lg'>Date tried</td>
            <td className='px-2 py-3 bg-4'>Location</td>
            <td className='px-2 py-3 bg-4 text-center'>
              Attempts on date
            </td>
            <td className='px-2 py-3 bg-4 rounded-tr-lg text-center'>Time stamps</td>
          </tr>
        </thead>
        <tbody>
          {
            !isFetching ?
              Insight?.data && Object.keys(Insight?.data)?.length ?
                Object.keys(Insight?.data).map((date, i) => {
                  let sectors = groupBy(Insight?.data[date], el => el.sector)
                  return Object.keys(sectors).map((sector, i) => {
                    let times = []
                    for (let el of sectors[sector]) {
                      if (el.times)
                        times?.push(...el?.times)
                    }
                    return <tr key={date + sector} className='text-sm'>
                      <td className='p-2 bg-gray rounded-l-lg'>
                        <p>{date}</p>
                        <p>Mon</p>
                      </td>
                      <td className='p-2 bg-gray'>{sector}</td>
                      <td className='p-2 bg-gray text-center'>
                        {times?.length} Times
                      </td>
                      <td className='p-2 bg-gray rounded-r-lg text-center'>
                        <div className='max-w-xs mx-auto'>
                          {times?.slice(0, 10).map((time, i) => <span key={i}>{moment(time, 'HH:mm:ss').format('hh:mm A')}, </span>)}
                        </div>
                      </td>
                    </tr>
                  })
                })
                :
                <>
                  <h1 className='text-3xl font-medium min-w-max text-center absolute w-full mt-10'>No Data Available</h1>
                </>
              :
              <></>
          }
        </tbody>
      </table>
    </div>
  )
}

export default DeniedAccess