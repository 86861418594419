// export const API_URL = 'http://localhost:8000/api';
// export const API_URL = 'https://aqqess-backend-t8tt7.ondigitalocean.app/api';
export const API_URL = 'https://api.aqqess.me/api';


export const endpoints = {
  url: API_URL,
  auth: '/auth',
  register: '/register',
  login: '/login',
  company: '/company',
  profile: '/profile',
  sendOtp: '/otp/send',
  verifyOtp: '/otp/verify',
  resetPassword: '/password/reset',
  updatePassword: '/password/update',
  validate: '/validate',
  employees: '/employees',
  employee: '/employee',
  sector: '/sector',
  validateScanner: '/validate/scanner',
  device: '/device',
  scanner: '/scanner',
  subdomain: '/subdomain',
  validateSubDomain: '/validate/subdomain',
  accessRequest: '/access/request',
  pastRequest: '/access/pastRequest',
  tempRequest: '/access/temporaryRequest',
  deniedAccess: '/employee/deniedAccess',
  sectorVisits: '/sector/visits',
  removeEmpFromSector: '/sector/remove/security',
  activeRequest: '/access/activeRequest',
  visitorRequest: '/access/visitor',
  eqInsight: '/quickInsight/employee',
  sqInsight: '/quickInsight/sector',
  branch: '/branch',
  branchAdmin: '/branch/admin',
};

