import React, { useState } from 'react'
import Auth from './Auth'
import Logo from '../../assets/logo.svg'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import validator from 'validator'
import { useSendOtp } from '../../hooks'

function ForgotPassword() {
  const history = useHistory()
  const [email, setEmail] = useState('')

  let { mutate, isLoading } = useSendOtp(() => history.push('/verify', { email, register: false, type: history.location.state?.type, branchName: history.location.state?.branchName }))

  const handleSubmit = (e) => {
    e.preventDefault();
    mutate({ email, type: history.location.state?.type, flow: 'reset' })
  }

  return (
    <Auth>
      {history.location.state?.branchName ? <div className='max-w-max absolute top-10 left-5'>
        <h1 className='text-4xl font-medium text-cyan'>{history.location.state?.branchName}</h1>
      </div> : ''}
      <form onSubmit={handleSubmit} className='w-full lg:max-w-md 2xl:max-w-lg flex flex-col gap-3'>
        <h1 className='text-4xl 2xl:text-5xl font-semibold mb-10 text-center'>Forgot password</h1>
        <h1 className='text-2xl font-light'>Registered Email ID</h1>
        <input type="email" placeholder='Enter your email' value={email} onChange={(e) => setEmail(e.target.value)} className='py-2 px-4 rounded-xl bg-[#0A0817] text-[#6993FF] w-full text-lg outline-none border-none' />

        <div className='flex flex-col justify-end items-end gap-1 mt-2'>
          <button disabled={!validator.isEmail(email) || isLoading} className='bg-8 py-1.5 px-6 rounded-xl text-lg'>
            <span>Submit</span>
          </button>
        </div>
      </form>

      <div className='flex items-center justify-center gap-2 py-5 cursor-pointer absolute bottom-5 right-10'>
        <img src={Logo} alt="" />
        <h1 className='text-3xl font-medium'>Aqqess</h1>
      </div>

    </Auth>
  )
}

export default ForgotPassword