import './App.css';
import { BrowserRouter as Router } from 'react-router-dom';
import Routes from './Routes';
import { Toaster } from 'react-hot-toast';
import HttpService from './services/axios-interceptor';

function App() {
  HttpService.configure();
  return (
    <Router>
      <div><Toaster/></div>
      <Routes />
    </Router>
  );
}

export default App;
