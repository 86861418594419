import React, { useEffect, useRef, useState } from 'react'
import QuickInsight from '../Dashboard/QuickInsight'
import Insight from '../Dashboard/Insight'
import SectorVisit from '../Dashboard/SectorVisit'
import TemporaryAccess from '../Dashboard/TemporaryAccess'
import DeniedAccess from '../Dashboard/DeniedAccess'
import { useHistory } from 'react-router-dom'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { BranchService } from '../../services/branch.service'
import { useQuery } from 'react-query'
import { useUpdateEmployeeSector } from '../../hooks'
import toast from 'react-hot-toast'
import { useSelector } from 'react-redux'
import { selectUserSectors } from '../../slices/userSlice'
import { useClickOutside } from '../../services/constant'

const options = [
    // {
    //     id: 1,
    //     title: 'insights'
    // },
    {
        id: 2,
        title: 'sector visits'
    },
    {
        id: 3,
        title: 'temporary access'
    },
    {
        id: 4,
        title: 'denied access'
    },
]

function EmployeeInfo() {
    const history = useHistory()
    const { employeeId } = useParams();
    const [active, setActive] = useState('sector visits')
    const [sectors, setSectors] = useState([])
    const [sector, setSector] = useState('')
    const [editSector, setEditSector] = useState(false);
    const [userSectors, setUserSectors] = useState([])
    const initialSectors = useSelector(selectUserSectors);
    const [show, setShow] = useState(false);

    const ref = useRef();

    useClickOutside(() => setShow(false), ref)

    const { data: EmpRes } = useQuery('getEmployee', () => BranchService.getEmployee(employeeId), {
        retry: false,
        refetchOnWindowFocus: false,
        onSuccess: (res) => { setSectors(res?.data?.sectors) },
        onError: (er) => console.log(er)
    })

    useEffect(() => {
        if (initialSectors?.length) {
            setUserSectors(initialSectors)
        }
    }, [initialSectors?.length])

    useEffect(() => {
        let ids = sectors.map(el => el.id)
        if (initialSectors?.length) {
            setUserSectors(initialSectors?.filter(el => !ids.includes(el.id)))
        }
    }, [sectors])

    let { mutate: updateSectors } = useUpdateEmployeeSector(() => setEditSector(false))

    const removeSector = (i) => {
        let arr = [...sectors]
        let el = arr.splice(i, 1);
        setSectors([...arr])
    }

    const addSector = (el) => {
        let arr = [...userSectors]
        let i = userSectors.findIndex(item => item.id == el.id)
        arr.splice(i);
        setSectors([...sectors, el])
        setShow(false);
    }

    const closeEditSector = () => {
        setSector('');
        setSectors(EmpRes?.data?.sectors)
        setEditSector(false)
    }

    const handleSectors = () => {
        if (EmpRes?.data?.id) {
            if (sectors?.length) {
                updateSectors({ empId: EmpRes?.data?.id, sectors })
            }
            else {
                toast.error("Please add at least one sector", { id: 'error' })
            }
        }
    }

    return (
        <section className='p-3'>
            <div className='rounded-3xl bg-4 p-4 flex items-start gap-2 flex-wrap xl:flex-nowrap'>
                <svg onClick={() => history.goBack()} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-20 transform -translate-y-3 flex-shrink-0 cursor-pointer">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18" />
                </svg>

                <div className='flex-grow pt-3'>
                    <h1 className='text-lg xl:text-2xl font-medium'>{EmpRes?.data?.name}</h1>
                    <p className='xl:text-lg'>Employee ID: <span>{employeeId}</span></p>
                    <p className='xl:text-lg'>Employee Email: <span>{EmpRes?.data?.email}</span></p>
                </div>

                <div className='bg-gray py-2 px-5 rounded-xl w-full max-w-2xl'>
                    <div className='flex items-start justify-between'>
                        <h1 className='text-xl font-medium'>Assigned Sectors ({sectors?.length || 0} Sectors)</h1>
                        <button className='bg-green py-1 px-3 rounded-lg flex items-center gap-1 shadow-md' onClick={() => setEditSector(true)}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125" />
                            </svg>
                            <span className='text-sm font-medium'>Edit</span>
                        </button>
                    </div>
                    <div className='flex flex-wrap gap-2 text-sm py-2 items-center justify-center max-w-xl mx-auto'>
                        {
                            sectors?.length ?
                                sectors?.map((item, i) => (
                                    <div key={i} className='border-4 rounded-md border-purple-500 py-1 px-2'>
                                        <span>{item.name}</span>
                                    </div>
                                ))
                                :
                                <h1>No Sector Assigned</h1>
                        }
                    </div>
                </div>
            </div>

            <div className='flex items-center justify-center gap-10 py-5'>
                {options?.map((item) => <div key={item.id} className='max-w-max cursor-pointer' onClick={() => setActive(item.title)}>
                    <h1 className='text-xl pb-1 capitalize'>{item.title}</h1>
                    {active == item.title ? <div className='h-0.5 rounded-full bg-5'></div> : <div className='h-0.5'></div>}
                </div>)}
            </div>


            <div className='flex items-start gap-4 pr-5'>
                <div className='flex-grow'>
                    {active == "insights" ? <Insight /> : <></>}
                    {active == "sector visits" ? <SectorVisit employeeId={employeeId} /> : <></>}
                    {active == "temporary access" ? <TemporaryAccess /> : <></>}
                    {active == "denied access" ? <DeniedAccess employeeId={employeeId} /> : <></>}
                </div>

                <QuickInsight employeeId={employeeId} type={'employee'} />
            </div>


            {editSector ? <section className='fixed inset-0 grid place-items-center z-50'>
                <div className='fixed inset-0 bg-black bg-opacity-70' onClick={() => closeEditSector()}></div>

                <div className='bg-gray p-5 xl:p-7 rounded-xl z-10 w-full max-w-3xl relative opacAnimation'>
                    <div className='flex items-center justify-between'>
                        <h1 className='text-3xl font-medium'>Edit Accessible Sectors</h1>
                        <p className='text-lg'>Employee</p>
                    </div>

                    <div className='bg-[#474747] rounded-xl p-4 h-60 my-5'>
                        <div className='flex flex-wrap gap-4'>
                            {
                                sectors?.map((item, i) => <div key={i} className='rounded-md border-2 border-purple-400 bg-gray flex first-letter:items-center gap-3 py-1 px-2 h-max w-max'>
                                    <span>{item.name}</span>
                                    <svg onClick={() => removeSector(i)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 cursor-pointer">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 12h-15" />
                                    </svg>
                                </div>)
                            }
                            <div className='flex items-center border-2 border-blue-700 rounded-md h-max bg-gray relative' ref={ref}>
                                <input type="text" onFocus={() => setShow(true)} placeholder='Enter new sector' value={sector} onChange={(e) => setSector(e.target.value)} className='py-1 px-2 flex-grow w-40 bg-transparent outline-none border-none' />
                                <svg
                                    // onClick={() => { if (sector) { setSectors([...sectors, sector]); setSector('') } }}
                                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 mr-2 cursor-pointer">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                                </svg>
                                {show ? <div className='bg-gray rounded-lg p-1 absolute top-9 left-0 w-full flex flex-col gap-1'>
                                    {
                                        userSectors?.filter(el => el?.name?.toLowerCase()?.includes(sector?.toLowerCase()) && (EmpRes?.data?.role == 'user' ? el.sectorType !== 'COMMON' : true))?.length
                                            ?
                                            userSectors?.filter(el => el?.name?.toLowerCase()?.includes(sector?.toLowerCase()) && (EmpRes?.data?.role == 'user' ? el.sectorType !== 'COMMON' : true))?.slice(0, 5)?.map((item, i) => <h1 key={i} className='text-sm py-1 px-2 rounded-md hover:bg-gray-800 cursor-pointer' onClick={() => addSector(item)}>{item?.name}</h1>)
                                            :
                                            <h1 className='text-sm py-1 px-2'>No Sector Found</h1>
                                    }
                                </div> : <></>}
                            </div>
                        </div>
                    </div>

                    <div className='flex items-center justify-between'>
                        <button className='py-2 px-10 rounded-xl bg-orange' onClick={() => closeEditSector()}>Cancel</button>
                        <button onClick={() => handleSectors()} className='py-2 px-6 rounded-xl bg-green'>Update Sectors</button>
                    </div>
                </div>
            </section> : <></>}
        </section>
    )
}

export default EmployeeInfo