import React from 'react'
import Loader from '../Reusable/Loader'
import { useEffect } from 'react'

function Branch() {
    useEffect(() => {
        window.location.replace("/")
    }, [])
    return (
        <Loader />
    )
}

export default Branch