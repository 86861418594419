import React, { useState } from 'react'
import { useQuery } from 'react-query'
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { BranchService } from '../../services/branch.service'
import moment from 'moment'
import { formattedDifference, week } from '../../services/constant'
import 'react-day-picker/dist/style.css';
import { DayPicker } from 'react-day-picker';

function DetailedSector() {
  const history = useHistory()
  const { sectorName } = useParams()
  const [search, setSearch] = useState('')
  const [filters, setFilters] = useState({
    sameBranch: true,
    otherBranch: true,
    tempAccess: true,
    visitor: false
  })
  const [dates, setDates] = useState({
    startDate: moment().subtract(30, 'days'),
    endDate: moment()
  })
  const [activeDate, setActiveDate] = useState('')

  const { data: visitRes, isFetching } = useQuery('getSectorVisit', () => BranchService.getSectorVisit(sectorName), {
    retry: false,
    refetchOnWindowFocus: false,
    onError: res => console.log(res),
  })

  const filter = (el) => {
    let searchBoolean = el?.name?.toLowerCase()?.includes(search?.toLowerCase());
    let assigned = true;
    let sameBranch = true;
    let otherBranch = true;
    let visitor = true;
    let dateBoolean = (new Date(el.createdAt) >= new Date(dates.startDate)) && (new Date(el.createdAt) <= new Date(dates.endDate))
    if (el.role == 'security' && el.assisted) {
      assigned = false
    }
    if (filters.visitor) {
      visitor = filters.visitor ? el.idType ? true : false : false
      return searchBoolean && assigned && visitor && dateBoolean
    }

    sameBranch = filters.sameBranch ? el.empBranch === el.branchId : el.empBranch !== el.branchId
    otherBranch = filters.otherBranch ? el.empBranch !== el.branchId : el.empBranch === el.branchId;


    return searchBoolean && assigned && (sameBranch || otherBranch) && dateBoolean
  }

  return (
    <section className='flex-grow px-5'>
      {activeDate ? <div className='fixed inset-0 z-50 grid place-items-center opacAnimation2'>
        <div className='fixed inset-0 bg-black bg-opacity-90 cursor-pointer' onClick={() => setActiveDate(false)}></div>
        <DayPicker
          className='relative z-10 bg-2 p-5 rounded-xl shadow-lg'
          mode="single"
          disabled={date => activeDate == 'endDate' ? date < moment(dates.startDate).toDate() : false}
          selected={dates[activeDate]}
          modifiersStyles={{
            selected: {
              background: '#411EA6'
            }
          }}
          onSelect={(date) => { setDates({ ...dates, [activeDate]: date }); setTimeout(() => setActiveDate(false), 700) }}
        />
      </div> : <></>}
      <div className='sticky top-[96px] bg-1 z-10 pt-5'>
        <div className='flex items-center gap-4 cursor-pointer max-w-max' onClick={() => history.goBack()}>
          <svg width="70" height="23" className='cursor-pointer' viewBox="0 0 90 23" fill="none" xmlns="http://www.w3.org/2000/svg" >
            <path d="M88.0127 9.9082C88.8411 9.9082 89.5127 10.5798 89.5127 11.4082C89.5127 12.2366 88.8411 12.9082 88.0127 12.9082V9.9082ZM1.25131 12.4689C0.665527 11.8831 0.665527 10.9333 1.25131 10.3475L10.7973 0.801601C11.383 0.215815 12.3328 0.215815 12.9186 0.801601C13.5044 1.38739 13.5044 2.33714 12.9186 2.92292L4.4333 11.4082L12.9186 19.8935C13.5044 20.4793 13.5044 21.429 12.9186 22.0148C12.3328 22.6006 11.383 22.6006 10.7973 22.0148L1.25131 12.4689ZM88.0127 12.9082L2.31197 12.9082V9.9082L88.0127 9.9082V12.9082Z" fill="white" />
          </svg >
          <h1 className='text-3xl'>{sectorName}</h1>
        </div>
        <div className='flex items-end justify-between w-full max-w-7xl mx-auto pb-4'>
          <div className='flex items-center gap-2 bg-2 py-1 px-3 rounded-full flex-grow max-w-lg'>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
            </svg>
            <input type="text" value={search} onChange={(e) => setSearch(e.target.value)} placeholder={'Search for employee '} className='placeholder:text-[#6F5990] bg-transparent flex-grow outline-none border-none p-1' />
          </div>
          <div>
            <div className='flex items-center gap-3'>
              <h1>Filter: </h1>
              <div className='flex items-center gap-1.5 cursor-pointer' onClick={() => setFilters({ ...filters, sameBranch: !filters.sameBranch })}>
                <div className='w-5 h-5 rounded border-2 border-[#28232F] bg-[#28232F] grid place-items-center'>
                  {filters.sameBranch ? <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-4 h-4">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                  </svg> : <></>}
                </div>
                <h1>Part of branch</h1>
              </div>
              {/* <div className='flex items-center gap-1.5 cursor-pointer' onClick={() => setFilters({ ...filters, tempAccess: !filters.tempAccess })}>
              <div className='w-5 h-5 rounded border-2 border-[#28232F] grid place-items-center'>
                {filters.tempAccess ? <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-4 h-4">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                </svg> : <></>}
              </div>
              <h1>Temp Access</h1>
            </div> */}
              <div className='flex items-center gap-1.5 cursor-pointer' onClick={() => setFilters({ ...filters, otherBranch: !filters.otherBranch })}>
                <div className='w-5 h-5 rounded border-2 border-[#3C3447] bg-[#28232F] grid place-items-center'>
                  {filters.otherBranch ? <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-4 h-4">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                  </svg> : <></>}
                </div>
                <h1>From other branch</h1>
              </div>
              <div className='flex items-center gap-1.5 cursor-pointer' onClick={() => setFilters({ ...filters, visitor: !filters.visitor })}>
                <div className='w-5 h-5 rounded border-2 border-[#344735] bg-[#242F23] grid place-items-center'>
                  {filters.visitor ? <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-4 h-4">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                  </svg> : <></>}
                </div>
                <h1>Show only Visitors</h1>
              </div>
            </div>
            <div className='flex items-center justify-end gap-4 text-lg mt-4'>
              <div className='flex items-center gap-2'>
                <h1>From: </h1>
                <div className='border-b-2 border-[#474747] p-0.5 w-24 text-center' onClick={() => setActiveDate('startDate')}>
                  <h1 className='text-base'>{dates.startDate ? moment(dates.startDate).format("DD/MM/YY") : 'Start Date'}</h1>
                </div>
              </div>
              <div className='flex items-center gap-2'>
                <h1>To: </h1>
                <div className='border-b-2 border-[#474747] p-0.5 w-24 text-center' onClick={() => setActiveDate('endDate')}>
                  <h1 className='text-base'>{dates.endDate ? moment(dates.endDate).format("DD/MM/YY") : 'End Date'}</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <table className='table-auto w-full max-w-7xl mx-auto' style={{ borderCollapse: 'separate', borderSpacing: '0 6px' }}>
        <thead className='rounded-lg sticky top-0'>
          <tr>
            <td className='px-2 py-3 bg-4 rounded-tl-lg'>Date</td>
            <td className='px-2 py-3 bg-4'>Employee</td>
            <td className='px-2 py-3 bg-4'>
              <div className='flex items-center min-w-max gap-32'>
                <h1>Enter time</h1>
                <h1>Exit time</h1>
              </div>
            </td>
            <td className='px-2 py-3 bg-4 text-center'>
              Entry type
            </td>
            <td className='px-2 py-3 bg-4 rounded-tr-lg'>No. of Attempts</td>
          </tr>
        </thead>
        <tbody className='relative'>
          {
            isFetching ?
              <></>
              : visitRes?.data?.filter(filter)?.length ?
                visitRes?.data?.filter(filter)?.map((item, i) => {
                  const duration = moment.duration(moment(item.exit).diff(item.entry));
                  const hours = Math.floor(duration.asHours());
                  const minutes = duration.minutes();
                  return (<tr key={i} className={`text-sm ${item?.idType ? 'bg-[#344735]' : item.empBranch !== item.branchId ? 'bg-transparent' : 'bg-[#28232F]'}`}>
                    <td className={`p-2 ${item?.idType ? 'border-[#242F23]' : item.empBranch !== item.branchId ? 'border-[#28232F]' : 'border-[#28232F]'} border-y-4 border-l-4 rounded-l-xl`}>
                      <p>{item?.date}</p>
                      <p>{week[moment(`${item?.createdAt}`).day()]}</p>
                    </td>
                    <td className={`p-2 ${item?.idType ? 'border-[#242F23]' : item.empBranch !== item.branchId ? 'border-[#28232F]' : 'border-[#28232F]'} border-y-4`}>{item.name}</td>
                    <td className={`p-2 ${item?.idType ? 'border-[#242F23]' : item.empBranch !== item.branchId ? 'border-[#28232F]' : 'border-[#28232F]'} border-y-4`}>
                      <div className='flex items-center min-w-max gap-1.5'>
                        <h1>{moment(item.entry).format('h:mm A')}</h1>
                        <svg width="30" height="6" viewBox="0 0 30 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M0.833333 3.11328C0.833333 4.58604 2.02724 5.77995 3.5 5.77995C4.97276 5.77995 6.16667 4.58604 6.16667 3.11328C6.16667 1.64052 4.97276 0.446615 3.5 0.446615C2.02724 0.446615 0.833333 1.64052 0.833333 3.11328ZM29.3319 3.61328C29.608 3.61328 29.8319 3.38942 29.8319 3.11328C29.8319 2.83714 29.608 2.61328 29.3319 2.61328V3.61328ZM3.5 3.61328H29.3319V2.61328H3.5V3.61328Z" fill="white" />
                        </svg>
                        {
                          item?.status == 'pending'
                            ?
                            <p className='text-sm text-yellow'>Pending</p>
                            :
                            <>
                              <p className='text-xs'>{formattedDifference(hours, minutes)}</p>
                              <svg width="30" height="6" viewBox="0 0 30 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.33203 2.61328C1.05589 2.61328 0.832031 2.83714 0.832031 3.11328C0.832031 3.38942 1.05589 3.61328 1.33203 3.61328V2.61328ZM24.4972 3.11328C24.4972 4.58604 25.6911 5.77995 27.1639 5.77995C28.6367 5.77995 29.8306 4.58604 29.8306 3.11328C29.8306 1.64052 28.6367 0.446615 27.1639 0.446615C25.6911 0.446615 24.4972 1.64052 24.4972 3.11328ZM1.33203 3.61328H27.1639V2.61328H1.33203V3.61328Z" fill="white" />
                              </svg>
                              <h1>{moment(item.exit).format('h:mm A')}</h1>
                            </>
                        }
                      </div>
                    </td>
                    <td className={`p-2 ${item?.idType ? 'border-[#242F23]' : item.empBranch !== item.branchId ? 'border-[#28232F]' : 'border-[#28232F]'} border-y-4 capitalize text-center`}>
                      {item.assisted ? <><p>{item?.assisted?.name}</p> <p className='text-xs italic ml-16 font-extralight'>Assisted</p></> : <span>Self</span>}
                    </td>
                    <td className={`p-2 ${item?.idType ? 'border-[#242F23]' : item.empBranch !== item.branchId ? 'border-[#28232F]' : 'border-[#28232F]'} border-y-4 border-r-4 rounded-r-xl`}>
                      <p>{item.attempt || 1} Self</p>
                      <p>{item.assisted ? 1 : 0} Assisted</p>
                    </td>
                  </tr>)
                })
                :
                <h1 className='text-3xl font-medium min-w-max text-center absolute w-full mt-10'>No Activity</h1>
          }
        </tbody>
      </table>
    </section>
  )
}

export default DetailedSector