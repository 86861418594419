import React from 'react'

function ConfirmationModal({ onClose, title, description, onOk, cancelText, okText }) {
    return (
        <section className='fixed inset-0 grid place-items-center z-50'>
            <div className='fixed inset-0 bg-black bg-opacity-70' onClick={onClose}></div>
            <div className='z-10 relative w-full max-w-2xl rounded-xl bg-gray min-h-[450px] flex flex-col items-center justify-center gap-4 p-10 opacAnimation'>
                <h1 className='text-5xl font-medium text-center my-6'>Are you sure?</h1>
                <p className='text-2xl text-center max-w-lg text-gray-200'>{title}</p>
                {description && <p className='text-lg text-center max-w-xl text-gray-400'>{description}</p>}
                <div className='w-full flex justify-between max-w-xl mt-auto'>
                    <button className='py-2 min-w-[120px] px-6 rounded-xl bg-green' onClick={onClose}>{cancelText || 'Cancel'}</button>
                    <button className='py-2 min-w-[120px] px-6 rounded-xl bg-red' onClick={onOk}>{okText || 'Yes'}</button>
                </div>
            </div>

        </section>
    )
}

export default ConfirmationModal