import React from 'react'

function BranchCloud() {
    return (
        <>
            <svg className='w-52 fixed top-0 left-0' viewBox="0 0 300 444" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="150" cy="150" r="140.5" transform="matrix(1 0 0 -1 -215.047 443.098)" fill="#12A5A5" stroke="#0C6F6F" stroke-width="19" />
                <circle cx="150" cy="150" r="140.5" transform="matrix(1 0 0 -1 -150.156 293.098)" fill="#12A5A5" stroke="#0C6F6F" stroke-width="19" />
                <circle cx="150" cy="150" r="140.5" transform="matrix(1 0 0 -1 -0.15625 143.098)" fill="#12A5A5" stroke="#0C6F6F" stroke-width="19" />
            </svg>
            <svg className='w-60 fixed bottom-0 left-0' viewBox="0 0 337 336" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="186.391" cy="393.205" r="140.5" fill="#4837AC" stroke="#2C226B" stroke-width="19" />
                <circle cx="36.3867" cy="309.158" r="140.5" fill="#4837AC" stroke="#2C226B" stroke-width="19" />
                <circle cx="-65.0469" cy="150.666" r="140.5" fill="#4837AC" stroke="#2C226B" stroke-width="19" />
            </svg>

        </>
    )
}

export default BranchCloud