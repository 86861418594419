
function SuccessModal({ onClose, message }) {
    return (
        <section className='fixed inset-0 grid place-items-center z-50'>
            <div className='fixed inset-0 bg-black bg-opacity-70' onClick={() => onClose()}></div>

            <div className='bg-gray p-10 rounded-xl z-10 flex flex-col items-center justify-center gap-6 w-full max-w-2xl relative opacAnimation min-h-[400px]'>
                <svg className='h-40' viewBox="0 0 157 201" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M55.5408 153.496C79.5531 153.496 99.019 134.03 99.019 110.017C99.019 86.0049 79.5531 66.5391 55.5408 66.5391C31.5284 66.5391 12.0625 86.0049 12.0625 110.017C12.0625 134.03 31.5284 153.496 55.5408 153.496Z" fill="#5141B2" />
                    <path d="M69.781 189.636C93.7934 189.636 113.259 170.17 113.259 146.158C113.259 122.146 93.7934 102.68 69.781 102.68C45.7686 102.68 26.3027 122.146 26.3027 146.158C26.3027 170.17 45.7686 189.636 69.781 189.636Z" fill="#5141B2" />
                    <path d="M96.888 164.366C114.897 164.366 129.497 149.766 129.497 131.757C129.497 113.748 114.897 99.1484 96.888 99.1484C78.8787 99.1484 64.2793 113.748 64.2793 131.757C64.2793 149.766 78.8787 164.366 96.888 164.366Z" fill="#5141B2" />
                    <path d="M153.367 99.1486C153.367 153.176 119.302 196.975 77.2802 196.975C35.2587 196.975 1.19336 153.176 1.19336 99.1486C1.19336 45.1203 35.2587 1.32227 77.2802 1.32227C119.302 1.32227 153.367 45.1203 153.367 99.1486ZM19.9471 99.1486C19.9471 139.859 45.6163 172.862 77.2802 172.862C108.945 172.862 134.614 139.859 134.614 99.1486C134.614 58.4373 108.945 25.4343 77.2802 25.4343C45.6163 25.4343 19.9471 58.4373 19.9471 99.1486Z" fill="#7E69FF" />
                    <path d="M79.9043 108.311H48.6543C47.8255 108.311 47.0306 108.64 46.4446 109.226C45.8585 109.812 45.5293 110.607 45.5293 111.436V122.916C45.5293 140.418 60.3379 146.225 63.3027 147.211C63.936 147.426 64.6226 147.426 65.2559 147.211C68.2246 146.225 83.0293 140.418 83.0293 122.916V111.436C83.0293 110.607 82.7001 109.812 82.114 109.226C81.528 108.64 80.7331 108.311 79.9043 108.311ZM79.9043 122.918C79.9043 138.234 66.9453 143.352 64.2793 144.242C61.6367 143.361 48.6543 138.248 48.6543 122.918V111.436H79.9043V122.918ZM55.3613 128.166C55.0681 127.873 54.9034 127.475 54.9034 127.061C54.9034 126.646 55.0681 126.248 55.3613 125.955C55.6545 125.662 56.0522 125.497 56.4668 125.497C56.8814 125.497 57.2791 125.662 57.5723 125.955L61.1543 129.537L70.9863 119.705C71.1315 119.56 71.3038 119.445 71.4935 119.366C71.6832 119.288 71.8865 119.247 72.0918 119.247C72.2971 119.247 72.5004 119.288 72.6901 119.366C72.8797 119.445 73.0521 119.56 73.1973 119.705C73.3424 119.85 73.4576 120.023 73.5362 120.212C73.6147 120.402 73.6552 120.605 73.6552 120.811C73.6552 121.016 73.6147 121.219 73.5362 121.409C73.4576 121.598 73.3424 121.771 73.1973 121.916L62.2598 132.854C62.1147 132.999 61.9423 133.114 61.7526 133.193C61.563 133.271 61.3596 133.312 61.1543 133.312C60.949 133.312 60.7456 133.271 60.556 133.193C60.3663 133.114 60.1939 132.999 60.0488 132.854L55.3613 128.166Z" fill="#242424" />
                </svg>

                <h1 className='text-3xl font-medium'>{message}</h1>
            </div>
        </section>
    )
}

export default SuccessModal