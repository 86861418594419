import React, { useEffect, useRef, useState } from 'react'
import Logo from '../../assets/logo.svg'
import OtpInput from 'react-otp-input';
import AdminCloud2 from './AdminCloud2';
import validator from 'validator';
import { toast } from 'react-hot-toast';
import { useRegisterCompany } from '../../hooks';
import { useHistory } from 'react-router-dom'
import useDebounce, { encryptPassword, useClickOutside } from '../../services/constant';
import { useQuery } from 'react-query';
import { AdminService } from '../../services/admin.service';

const options = [
    {
        id: 1,
        title: 'Company Details'
    },
    {
        id: 2,
        title: 'Admin Details'
    },
    {
        id: 3,
        title: 'URL'
    },
]

const hasNumber = (str) => /\d/.test(str)
const hasOnlyNumber = (str) => /^\d+$/.test(str)

function CompanySetup() {
    const history = useHistory()
    const [active, setActive] = useState(1)
    const [show, setShow] = useState(false)
    const [showC, setShowC] = useState(false);
    const [alpha, setAlpha] = useState('')
    const [numeric, setNumeric] = useState('')
    const [data, setData] = useState({
        companyName: '',
        location: '',
        noOfEmployees: '',
        adminEmail: '',
        adminName: '',
        password: '',
        cPassword: '',
    });
    const [validCompany, setValCompany] = useState();
    const [validAdmin, setValAdmin] = useState();
    const [lShow, setLShow] = useState(false);
    const [Countries, setCountries] = useState([]);
    const locRef = useRef();
    const [passErr, setPassErr] = useState('')
    const [cPassErr, setCPassErr] = useState('')

    useClickOutside(() => setLShow(false), locRef);

    const { refetch: getCountries } = useQuery("getCountries", () => AdminService.getCountries(data.location), {
        retry: false,
        enabled: false,
        refetchOnWindowFocus: false,
        onSuccess: res => {
            if (res?.data?.data) {
                setCountries(res?.data?.data)
            }
            else {
                setCountries([])
            }
        },
    })

    const { refetch: validateCompany, isFetching: valCompany } = useQuery('validateCompanyName', () => AdminService.validateCompanyName(data?.companyName), {
        retry: false,
        enabled: false,
        onSuccess: res => {
            setValCompany(res?.data)
        }
    })
    const { refetch: validateEmail, isFetching: valAdmin } = useQuery('validateAdminEmail', () => AdminService.validateAdminEmail(data?.adminEmail), {
        retry: false,
        enabled: false,
        onSuccess: res => {
            setValAdmin(res?.data)
        }
    })
    const { refetch: getSubDomain, isLoading: isFetching } = useQuery('getSubDomain', () => AdminService.getSubDomain(data?.companyName), {
        retry: false,
        enabled: false,
        onSuccess: res => {
            if (res?.data.includes("-")) {
                let [a, n] = res?.data.split("-")
                setAlpha(a);
                setNumeric(n)
            }
        }
    })

    const name = useDebounce(data.companyName, 500);

    useEffect(() => {
        setValCompany(false);
        if (name) {
            validateCompany()
        }
    }, [name]);

    const email = useDebounce(data.adminEmail, 500);

    useEffect(() => {
        setValAdmin(false);
        if (email) {
            validateEmail()
        }
    }, [email]);

    const password = useDebounce(data.password, 500);
    const cPassword = useDebounce(data.cPassword, 500);

    useEffect(() => {
        setPassErr('')
        setCPassErr('')
        if (password?.length > 3) {
            if (password?.length < 6) {
                setPassErr('Minimum 6 Characters')
            } else {
                if (cPassword?.length > 3) {
                    if (cPassword?.length < 6) {
                        setCPassErr('Minimum 6 Characters')
                    }
                    else if (password !== cPassword) {
                        setCPassErr('Password does not match')
                    }
                }
            }
        }

    }, [password, cPassword]);

    const handleNext = () => {
        if (active == 2) {
            if (data.password !== data.cPassword) {
                return toast.error("Password does not matched", { id: 'error' })
            }
        }
        setActive(active + 1);
    }

    useEffect(() => {
        if (active == 3) {
            getSubDomain()
        }
    }, [active])

    const handleChange = (e) => setData({ ...data, [e.target.id]: e.target.value });
    const { mutate: register, isLoading } = useRegisterCompany(() => history.push('/verify', { email: data.adminEmail, register: true, type: 'admin' }))

    const handleSubmit = (e) => {

        e.preventDefault();
        if (hasNumber(alpha) || !hasOnlyNumber(numeric)) {
            return toast.error("Please provide a valid url", { id: 'error' });
        }
        if (data.location?.split(",").length == 3) {
            register({
                companyName: data.companyName,
                location: data.location,
                noOfEmployees: data.noOfEmployees,
                adminEmail: data.adminEmail,
                adminName: data.adminName,
                password: encryptPassword(data.password),
                subdomain: alpha + "-" + numeric
            })
        }
        else {
            toast("Please select location from the list", { id: 'err' })
        }
    }

    const location = useDebounce(data.location, 500)

    useEffect(() => {
        getCountries();
    }, [location])


    const renderInput = (inputProps) => (
        <input {...inputProps} className="bg-transparent flex-grow border-b-2 h-10 outline-none text-2xl" />
    );

    return (
        <section className='bg-[#161F3B] w-full h-screen grid place-items-center'>
            <div className='flex items-center justify-center gap-2 py-5 cursor-pointer absolute top-5 left-10'>
                <img src={Logo} alt="" />
                <h1 className='text-3xl font-medium'>Aqqess</h1>
            </div>
            <div className='w-full max-w-5xl p-5 z-20'>
                <div className='flex items-center w-full justify-center'>
                    {options?.map(option => (
                        <>
                            <div onClick={() => setActive(option.id)} className={`w-14 h-14 grid place-items-center ${active >= option.id ? 'bg-[#5675D7]' : 'bg-white'} rounded-full mx-3 relative`}>
                                <span className={`text-2xl ${active >= option.id ? 'text-white' : 'text-black'}`}>{option.id}</span>
                                <span className='absolute -bottom-8 left-1/2 transform -translate-x-1/2 min-w-max text-lg'>{option.title}</span>
                            </div >
                            {
                                option.id < 3 ? <svg className={`mx-2 w-60 ${active > option.id ? 'fill-[#5675D7]' : 'fill-white'}`} viewBox="0 0 237 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0.0664062 8.01367C0.0664062 12.432 3.64813 16.0137 8.06641 16.0137C12.4847 16.0137 16.0664 12.432 16.0664 8.01367C16.0664 3.59539 12.4847 0.0136719 8.06641 0.0136719C3.64813 0.0136719 0.0664062 3.59539 0.0664062 8.01367ZM220.619 8.01367C220.619 12.432 224.2 16.0137 228.619 16.0137C233.037 16.0137 236.619 12.432 236.619 8.01367C236.619 3.59539 233.037 0.0136719 228.619 0.0136719C224.2 0.0136719 220.619 3.59539 220.619 8.01367ZM8.06641 9.51367H228.619V6.51367H8.06641V9.51367Z" />
                                </svg> : ''
                            }

                        </>
                    ))}
                </div>

                <div className='min-h-[330px] pt-20'>
                    {active == 1 ? <div className='w-full grid grid-cols-2 gap-x-16 gap-y-6'>
                        <div>
                            <h1 className='text-lg 2xl:text-xl mb-1 ml-1'>Company Name</h1>
                            <input type="text" id='companyName' value={data.companyName} onChange={handleChange} minLength={3} placeholder='Enter Company name' className={`py-2 px-4 rounded-xl bg-[#0A0817] text-[#6993FF] w-full text-lg outline-none border-2 ${validCompany ? 'border-[#FF0000]' : 'border-transparent'}`} />
                            <p className='text-right text-sm font-light tracking-wide m-0.5 text-[#FF5E5E] h-5'>{validCompany ? 'Company name already exist' : ''}</p>
                        </div>
                        <div className='relative'>
                            <h1 className='text-lg 2xl:text-xl mb-1 ml-1'>Company HQ</h1>
                            <div className='relative' ref={locRef}>
                                <input type="text" id='location' onFocus={() => setLShow(true)} value={data.location} onChange={handleChange} placeholder='Company Headquarter' className='py-2 px-4 rounded-xl bg-[#0A0817] text-[#6993FF] w-full text-lg outline-none border-none capitalize' />
                                {
                                    (lShow && location) ? <div className='bg-gray2 rounded-lg absolute top-10 left-0 w-full max-h-60 overflow-y-auto'>
                                        {
                                            Countries?.length ?
                                                Countries?.map((item, i) => <h1 key={i} className='px-3 py-1.5 border-b-2 border-[#3F3F3F] last:border-none cursor-pointer capitalize' onClick={() => { setLShow(false); setData({ ...data, location: item.location }) }}>{item.location}</h1>)
                                                :
                                                <h1 className='py-2 px-3 '>No result</h1>
                                        }

                                    </div>
                                        : <></>
                                }
                            </div>
                        </div>
                        <div>
                            <h1 className='text-lg 2xl:text-xl mb-1 ml-1'>Number of Employees</h1>
                            <div className='rounded-xl bg-[#0A0817] pr-2'>
                                <select type="text" placeholder='Enter Company name' id='noOfEmployees' value={data.noOfEmployees} onChange={handleChange} className='py-2 px-4 bg-[#0A0817] text-[#6993FF] accent-[#6993FF] w-full text-lg rounded-xl outline-none border-none'>
                                    <option value="" disabled selected>Choose number of employees</option>
                                    <option value="1-10">1-10</option>
                                    <option value="11-100">11-100</option>
                                    <option value="101-1000">101-1000</option>
                                    <option value="1000+">1000+</option>
                                </select>
                            </div>
                        </div>
                    </div>
                        :
                        active == 2
                            ?
                            <div className='w-full grid grid-cols-2 gap-x-16 gap-y-6'>
                                <div>
                                    <h1 className='text-lg 2xl:text-xl mb-1 ml-1'>Admin Name</h1>
                                    <input type="text" id='adminName' value={data.adminName} onChange={handleChange} minLength={3} placeholder='Enter admin name' className='py-2 px-4 rounded-xl bg-[#0A0817] text-[#6993FF] w-full text-lg outline-none border-none' />
                                </div>
                                <div>
                                    <h1 className='text-lg 2xl:text-xl mb-1 ml-1'>Admin Email ID</h1>
                                    <input type="email" id='adminEmail' value={data.adminEmail} onChange={handleChange} minLength={5} placeholder='Enter admin email' className={`py-2 px-4 rounded-xl bg-[#0A0817] text-[#6993FF] w-full text-lg outline-none border-2 ${validAdmin ? 'border-[#FF0000]' : 'border-transparent'}`} />
                                    <p className='text-right text-sm font-light tracking-wide m-0.5 text-[#FF5E5E] h-5'>{validAdmin ? 'Email already exist' : ''}</p>
                                </div>
                                <div>
                                    <h1 className='text-lg 2xl:text-xl mb-1 ml-1'>Password</h1>
                                    <div className='relative'>
                                        <input type={show ? 'text' : 'password'} id='password' value={data.password} onChange={handleChange} minLength={5} placeholder='Enter your password' className={`py-2 px-4 rounded-xl bg-[#0A0817] text-[#6993FF] w-full text-lg outline-none border-2 ${passErr ? 'border-[#FF0000]' : 'border-transparent'}`} />
                                        {!show ? <svg onClick={() => setShow(true)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 absolute top-2 right-3 cursor-pointer">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                        </svg>
                                            : <svg onClick={() => setShow(false)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 absolute top-2 right-3 cursor-pointer">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88" />
                                            </svg>}
                                        <p className='text-right text-sm font-light tracking-wide m-0.5 text-[#FF5E5E] h-5'>{passErr || ''}</p>
                                    </div>
                                </div>
                                <div>
                                    <h1 className='text-lg 2xl:text-xl mb-1 ml-1'>Confirm Password</h1>
                                    <div className='relative'>
                                        <input type={showC ? 'text' : 'password'} id='cPassword' value={data.cPassword} onChange={handleChange} minLength={5} placeholder='Confirm Password' className={`py-2 px-4 rounded-xl bg-[#0A0817] text-[#6993FF] w-full text-lg outline-none border-2 ${cPassErr ? 'border-[#FF0000]' : 'border-transparent'}`} />
                                        {!showC ? <svg onClick={() => setShowC(true)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 absolute top-2 right-3 cursor-pointer">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                        </svg>
                                            : <svg onClick={() => setShowC(false)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 absolute top-2 right-3 cursor-pointer">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88" />
                                            </svg>}
                                            <p className='text-right text-sm font-light tracking-wide m-0.5 text-[#FF5E5E] h-5'>{cPassErr || ''}</p>
                                    </div>
                                </div>
                            </div>
                            :
                            <div className='relative'>
                                <div className='absolute inset-0 z-10'></div>
                                <h1 className='text-3xl font-medium'>URL for your organization</h1>
                                {/* <p className='text-lg font-extralight'>This URL cannot be changed once set</p> */}

                                {isFetching ?
                                    <h1 className='text-xl'>Generating...</h1>
                                    : <div className='flex items-center gap-5 mt-10'>
                                        <div className='w-full max-w-xs'>
                                            <OtpInput
                                                value={alpha}
                                                onChange={(txt) => { }}
                                                numInputs={4}
                                                containerStyle="w-full max-w-xs gap-5 bg-[#253565] p-3 rounded-lg"
                                                renderInput={renderInput}
                                            />
                                            <p className='font-thin'>Alpha-numeric value</p>
                                        </div>
                                        <div className='w-16 h-0.5 mb-5 bg-gray-300 rounded-full'></div>
                                        <div className='w-full max-w-xs'>
                                            <OtpInput
                                                value={numeric}
                                                disabled
                                                onChange={(txt) => { }}
                                                numInputs={4}
                                                containerStyle="w-full max-w-xs gap-5 bg-[#253565] p-3 rounded-lg"
                                                isInputNum={true}
                                                renderInput={renderInput}
                                            />
                                            <p className='font-thin'>Numeric value</p>
                                        </div>
                                        <div className='mb-5 text-2xl font-medium'>
                                            .aqqess.me
                                        </div>
                                    </div>}
                            </div>
                    }
                </div>
                <div className='flex items-center justify-between'>
                    {active > 1
                        ?
                        <button onClick={() => setActive(active - 1)} className='py-1.5 px-6 min-w-[120px] rounded-xl bg-yellow text-lg'>Back</button>
                        :
                        <span></span>
                    }
                    {
                        active < 3
                            ?
                            <button onClick={() => handleNext()}
                                disabled={
                                    active == 1 ?
                                        !data.companyName || !data.location || !data.noOfEmployees || validCompany
                                        :
                                        active == 2 ?
                                            (!validator.isEmail(data.adminEmail) || data.adminName?.length < 3 || data.password?.length < 6 || data.cPassword?.length < 6 || validAdmin)
                                            :
                                            false
                                }
                                className='py-1.5 px-6 min-w-[120px] rounded-xl bg-green text-lg'>Next</button>
                            : <button
                                disabled={(alpha?.length !== 4 || numeric?.length !== 4) || isLoading}
                                onClick={handleSubmit}
                                className='py-1.5 px-6 min-w-[220px] rounded-xl bg-[#18C070] text-lg flex items-center justify-center'>
                                {
                                    isLoading
                                        ?
                                        <div className='w-7 h-7 rounded-full border-2 border-white border-b-transparent animate-spin'></div>
                                        : <span>Create Organization</span>
                                }
                            </button>
                    }
                </div>
            </div>
            <AdminCloud2 />
        </section>
    )
}

export default CompanySetup

