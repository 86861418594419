import moment from 'moment'
import React, { useState } from 'react'
import { useQuery } from 'react-query'
import { BranchService } from '../../services/branch.service'
import MiniLoader from '../Reusable/MiniLoader'
import RequestCard from '../Branch/RequestCard'
import RequestList from '../Branch/RequestList'
import { useAddTempRequest } from '../../hooks'
import { DayPicker } from 'react-day-picker'
import { useSelector } from 'react-redux'
import { selectEmployees, selectUserInfo, selectUserSectors } from '../../slices/userSlice'
import { useEffect } from 'react'
import { useClickOutside } from '../../services/constant'
import { useRef } from 'react'
import toast from 'react-hot-toast'
import SuccessModal from '../Reusable/SuccessModal'

const options = [
  {
    id: 1,
    title: 'requests'
  },
  {
    id: 2,
    title: 'time'
  },
  {
    id: 3,
    title: 'recent'
  },
  {
    id: 4,
    title: 'present'
  },
]

function TemporaryAccess() {
  const [active, setActive] = useState('requests')
  const [activeType, setActiveType] = useState('mine');
  const [search, setSearch] = useState('')
  const userInfo = useSelector(selectUserInfo)
  const [newRequest, setNewRequest] = useState(false);

  const { data: RequestRes, isLoading, refetch } = useQuery('getTempRequest', BranchService.getTempRequest, {
    retry: false,
    // refetchOnMount: false,
    refetchOnWindowFocus: false,
    onError: res => console.log(res)
  })

  const { data: presentData } = useQuery('getActiveRequest', BranchService.getActiveRequest, {
    retry: false,
    refetchOnMount: false,
    // refetchOnWindowFocus: false,
    onError: res => console.log(res)
  })

  useQuery('tempRequestSeen', BranchService.tempRequestSeen, {
    retry: false,
    refetchOnMount: false,
  })

  const filter = (el) => {
    if (el) {
      let type = (activeType == 'other') ? (el.requestType == 'branch') : (el.requestType == 'sector')
      let activeBoolean = false;
      if (active == 'time' && (moment(el.dateTo).diff(moment(), 'days') < 3 || moment().diff(moment(el.createdAt), 'days') < 3) && el.status == 0) {
        activeBoolean = true
      }
      else if (active == 'requests' && el.status == 0) {
        activeBoolean = true
      }
      else if (active == 'recent' && el.status != 0) {
        activeBoolean = true
      }
      else if (active == 'present') {
        activeBoolean = true
      }
      let searchBoolean = el?.employeeId?.toLowerCase().includes(search?.toLowerCase()) || el?.sector?.toLowerCase().includes(search?.toLowerCase()) || el?.name?.toLowerCase().includes(search?.toLowerCase())

      return type && activeBoolean && searchBoolean
    }
  }

  return (
    <section className='w-full px-5 mx-auto max-w-7xl'>
      <div className='sticky top-[96px] bg-1 z-20 bg-1 pt-6'>
        <div className='pb-6 flex items-center gap-10'>
          <div className='flex items-center justify-between gap-6 2xl:gap-8 bg-2 py-2 px-6 rounded-xl border-4 border-transparent relative w-full max-w-max'>
            <span className='absolute -top-4 left-4 font-extralight px-1'>Within Your Branch</span>
            {options?.map((item) => <div key={item.id} className='max-w-max cursor-pointer' onClick={() => { setActive(item.title); setActiveType('mine') }}>
              <h1 className={`pb-0.5 text-lg capitalize ${(active == item.title && activeType == 'mine') ? 'text-white' : 'text-gray-500'}`}>{item.title}</h1>
              {(active == item.title && activeType == 'mine') ? <div className='h-0.5 rounded-full bg-purple'></div> : <div className='h-0.5'></div>}
            </div>)}
          </div>

          <div className='flex items-center justify-between gap-6 2xl:gap-8 bg-1 border-4 border-[#794FAC] py-2 px-6 rounded-xl relative w-full max-w-max'>
            <span className='absolute -top-4 left-4 font-extralight bg-1 px-1'>To Your Branch</span>
            {options?.map((item) => <div key={item.id} className='max-w-max cursor-pointer' onClick={() => { setActive(item.title); setActiveType('other') }}>
              <h1 className={`pb-0.5 text-lg capitalize ${(active == item.title && activeType == 'other') ? 'text-white' : 'text-gray-500'}`}>{item.title}</h1>
              {(active == item.title && activeType == 'other') ? <div className='h-0.5 rounded-full bg-purple'></div> : <div className='h-0.5'></div>}
            </div>)}
          </div>

          <button className='bg-9 px-6 py-1.5 rounded-xl ml-auto' onClick={() => setNewRequest(true)}>Assign Access</button>
        </div>

        <div className='flex items-center justify-between pr-10 pb-5'>
          <div className='flex items-center gap-2 bg-2 py-1 px-3 rounded-full flex-grow max-w-xl'>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
            </svg>
            <input type="text" value={search} onChange={(e) => setSearch(e.target.value)} placeholder={'Search for employee or sector'} className='placeholder:text-[#7E69FF] bg-transparent flex-grow outline-none border-none p-1' />
          </div>
          <div>
            <h1>
              {
                active == 'recent'
                  ?
                  <p className='text-xl text-gray-500'><span className='text-green'>{RequestRes?.data.filter(filter)?.filter(el => el.status == 1)?.length}</span>/<span className='text-red'>{RequestRes?.data.filter(filter)?.filter(el => el.status == 2)?.length}</span>/<span className='text-white'>{RequestRes?.data?.length}</span></p>
                  :
                  active == 'requests' ?
                    <span className='text-xl text-[#B880FF]'>{RequestRes?.data.filter(filter)?.length} Requests</span>
                    :
                    active == 'time' ?
                      <span className='text-xl text-[#BD4D4D]'>{RequestRes?.data.filter(filter)?.length} Requests</span>
                      :
                      <></>
              }
            </h1>
          </div>
        </div>
      </div>
      {
        isLoading ? <MiniLoader /> :

          (active == 'requests' || active == 'time')
            ?
            <div className='flex flex-wrap gap-5 py-2'>
              {
                RequestRes?.data?.filter(filter)?.length ?
                  RequestRes?.data?.filter(filter).map((item, i) => <RequestCard key={i} item={item} activeType={activeType} active={active} refetch={refetch} />)
                  :
                  <h1 className='text-3xl font-medium min-w-max text-center w-full mt-10'>No Request Available</h1>
              }
            </div>
            :
            active == 'recent' ? <div className=''>
              <table className='table-auto w-full' style={{ borderCollapse: 'separate', borderSpacing: '0 4px' }}>
                <thead className='rounded-lg'>
                  <tr>
                    <td className='px-2 py-3 bg-4 rounded-tl-lg'>Req. Date</td>
                    <td className='px-2 py-3 bg-4'>Employee Name</td>
                    <td className='px-2 py-3 bg-4'>{activeType == 'other' ? 'Parent Branch' : 'Sector Name'}</td>
                    <td className='px-2 py-3 bg-4'>
                      <div className='text-center'>
                        <h1>Date Requested</h1>
                      </div>
                    </td>
                    <td className='px-2 py-3 bg-4'>Purpose</td>
                    <td className='px-2 py-3 bg-4 rounded-tr-lg'>Status</td>
                  </tr>
                </thead>
                <tbody className='relative'>
                  {RequestRes?.data.filter(filter)?.length ?
                    RequestRes?.data.filter(filter)?.map((item, i) => <RequestList employee={false} key={i} i={i} item={item} />)
                    :
                    <h1 className='text-3xl font-medium min-w-max text-center absolute w-full mt-10'>No Request Available</h1>
                  }
                </tbody>
              </table>
            </div>
              :
              <div className=''>
                <table className='table-auto w-full' style={{ borderCollapse: 'separate', borderSpacing: '0 4px' }}>
                  <thead className='rounded-lg'>
                    <tr>
                      <td className='px-2 py-3 bg-4 rounded-tl-lg'>Employee Name</td>
                      <td className='px-2 py-3 bg-4'>{activeType == 'other' ? 'Parent Branch' : 'Sector Name'}</td>
                      <td className='px-2 py-3 bg-4'>
                        <div className='text-center'>
                          <h1>Date Requested</h1>
                        </div>
                      </td>
                      <td className='px-2 py-3 bg-4'>Purpose</td>
                      <td className='px-2 py-3 bg-4'>First Access</td>
                      <td className='px-2 py-3 bg-4 rounded-tr-lg'>Remaining</td>
                    </tr>
                  </thead>
                  <tbody className='relative'>
                    {presentData?.data.filter(filter)?.length ?
                      presentData?.data.filter(filter)?.map((item, i) => (
                        <tr key={i} className='text-sm'>
                          <td className='p-2 pb-2.5 bg-gray rounded-l-lg'>{item.name}</td>
                          <td className='p-2 pb-2.5 bg-gray'>{item.sector || item.branchName}</td>
                          <td className='p-2 pb-2.5 bg-gray'>
                            <div className='flex items-center justify-center min-w-max gap-1'>
                              <h1>{moment(item.dateFrom).format("DD/MM/YY")}</h1>
                              <svg width="30" height="6" viewBox="0 0 30 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.833333 3.11328C0.833333 4.58604 2.02724 5.77995 3.5 5.77995C4.97276 5.77995 6.16667 4.58604 6.16667 3.11328C6.16667 1.64052 4.97276 0.446615 3.5 0.446615C2.02724 0.446615 0.833333 1.64052 0.833333 3.11328ZM29.3319 3.61328C29.608 3.61328 29.8319 3.38942 29.8319 3.11328C29.8319 2.83714 29.608 2.61328 29.3319 2.61328V3.61328ZM3.5 3.61328H29.3319V2.61328H3.5V3.61328Z" fill="white" />
                              </svg>
                              <p className='text-xs'>{moment(item?.dateTo).diff(moment(item.dateFrom), 'days')} Days</p>
                              <svg width="30" height="6" viewBox="0 0 30 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.33203 2.61328C1.05589 2.61328 0.832031 2.83714 0.832031 3.11328C0.832031 3.38942 1.05589 3.61328 1.33203 3.61328V2.61328ZM24.4972 3.11328C24.4972 4.58604 25.6911 5.77995 27.1639 5.77995C28.6367 5.77995 29.8306 4.58604 29.8306 3.11328C29.8306 1.64052 28.6367 0.446615 27.1639 0.446615C25.6911 0.446615 24.4972 1.64052 24.4972 3.11328ZM1.33203 3.61328H27.1639V2.61328H1.33203V3.61328Z" fill="white" />
                              </svg>
                              <h1>{moment(item.dateTo).format("DD/MM/YY")}</h1>
                            </div>
                          </td>
                          <td className='p-2 pb-2.5 bg-gray'>
                            <p>{item.purpose}</p>
                          </td>

                          <td className='p-2 pb-2.5 bg-gray'>
                            <p className='font-light'>{item.firstAccess ? (moment(item.firstAccess).format("DD/MM/YY")) : <span className='text-sm italic font-light'>~no scan yet~</span>}</p>
                          </td>

                          <td className='p-2 pb-2.5 bg-gray rounded-r-lg'>
                            <p className='font-light'>{moment(item.dateTo).diff(moment(), 'days')} Day(s) Left</p>
                          </td>
                        </tr>
                      ))
                      :
                      <h1 className='text-3xl font-medium min-w-max text-center absolute w-full mt-10'>No Request Available</h1>
                    }
                  </tbody>
                </table>
              </div>}

      {newRequest ? <NewRequest setNewRequest={setNewRequest} /> : <></>}

    </section>
  )
}

export default TemporaryAccess


const NewRequest = ({ setNewRequest, userInfo }) => {
  const employees = useSelector(selectEmployees);
  const initialSectors = useSelector(selectUserSectors);

  const [activeDate, setActiveDate] = useState(false);
  const [employee, setEmployee] = useState()
  const [empSectors, setEmpSectors] = useState([])
  const [userSectors, setUserSectors] = useState([])
  const [show, setShow] = useState(false);
  const [eShow, setEShow] = useState(false);
  const [sector, setSector] = useState('')


  const [success, setSuccess] = useState(false);
  const [purpose, setPurpose] = useState('')
  const [dates, setDates] = useState({
    startDate: '', endDate: ''
  })
  const [oneDay, setOneDay] = useState(false);

  const ref = useRef();
  const eRef = useRef();
  useClickOutside(() => eShow ? setEShow(false) : setShow(false), eShow ? eRef : ref)

  const onCancel = () => {
    setEmpSectors([]);
    setEmployee();
    setPurpose('');
    setOneDay(false)
    setSuccess(false);
    setDates({ startDate: '', endDate: '' })
    setNewRequest(false);
  }

  const handleChange = (e) => setEmployee({ employeeId: e.target.value });


  const { mutate: addRequest, isLoading: requesting } = useAddTempRequest(() => setSuccess(true))

  const submitRequest = (e) => {
    e.preventDefault();

    let endDate;
    if (oneDay) {
      endDate = moment(dates.startDate).add(1, 'days').toDate();
    }
    else {
      endDate = moment(dates.endDate).add(1, 'days').toDate()
    }

    if (empSectors?.length) {
      addRequest({
        dateFrom: dates.startDate,
        dateTo: endDate,
        purpose,
        id: employee?.id,
        sectors: empSectors?.map(el => el.id)
      })
    }
    else {
      toast.error("Please add at least one sector", { id: 'error' })
    }
  }

  useEffect(() => {
    let ids = employee?.sectors?.map(el => el.sectorId)
    if (initialSectors?.length && ids) {
      setUserSectors(initialSectors?.filter(el => (!ids.includes(el.id) && el.sectorType == 'SPECIFIC')))
    }
  }, [employee?.sectors])


  const removeEmpSector = (i) => {
    let arr = [...empSectors]
    arr.splice(i);
    setEmpSectors([...arr])
  }

  const addEmpSector = (el) => {
    setEmpSectors([...empSectors, el]);
    setShow(false);
  }

  return (
    <>
      {activeDate ? <div className='fixed inset-0 z-50 grid place-items-center opacAnimation2'>
        <div className='fixed inset-0 bg-black bg-opacity-80 cursor-pointer' onClick={() => setActiveDate(false)}></div>
        <DayPicker
          className='relative z-10 bg-2 p-5 rounded-xl shadow-lg'
          mode="single"
          disabled={date => activeDate == 'endDate' ? dates.startDate ? date < moment(dates.startDate).toDate() : date < new Date() : date < moment().toDate()}
          selected={dates[activeDate]}
          modifiersStyles={{
            selected: {
              background: '#411EA6'
            }
          }}
          onSelect={(date) => { setDates({ ...dates, [activeDate]: date }); setTimeout(() => setActiveDate(false), 1000) }}
        />
      </div> : <></>}
      <section className='fixed bg-transparent inset-0 grid place-items-center z-30'>
        <div className='fixed inset-0 bg-black bg-opacity-70' onClick={onCancel}></div>

        <form className='w-full max-w-5xl py-5 px-10 rounded-xl relative bg-gray'>

          <h1 className='text-3xl font-medium'>Assign Temporary Access</h1>

          <div className='my-10 grid grid-cols-2 gap-x-10 gap-y-4'>
            <div ref={eRef}>
              <h1 className='text-lg mb-0.5'>Employee id</h1>
              <div className='relative'>
                <input type="text" id='id' value={employee?.employeeId} onChange={handleChange} onFocus={() => setEShow(true)} required placeholder='Enter Employee ID' minLength={3} className='py-1.5 px-3 rounded-lg bg-gray2 outline-none border-none w-full uppercase placeholder:capitalize' />
                {eShow ? <div className='bg-1 rounded-lg p-1 absolute top-9 left-0 w-full flex flex-col gap-1'>
                  {
                    employees?.filter(el => el?.employeeId?.toLowerCase()?.includes(employee?.employeeId?.toLowerCase()))?.length
                      ?
                      employees?.filter(el => el?.employeeId?.toLowerCase()?.includes(employee?.employeeId?.toLowerCase()))?.slice(0, 5)?.map((item, i) => <h1 key={i} className='text-sm py-1 px-2 rounded-md hover:bg-gray-800 cursor-pointer uppercase' onClick={() => { setEmployee(item); setEShow(false) }}>{item?.employeeId}</h1>)
                      :
                      <h1 className='text-sm py-1 px-2'>No Employee Found</h1>
                  }
                </div> : <></>}
              </div>
            </div>
            <div>
              <h1 className='text-lg mb-0.5'>Employee name</h1>
              <input type="text" id='name' value={employee?.name || ''} readOnly placeholder='Employee name shows up here' className='py-1.5 px-3 rounded-lg outline-none border-none w-full bg-black' />
            </div>
            <div className=''>
              <h1 className='text-lg'>Select Purpose</h1>
              <div className='bg-[#474747] px-3 py-1 rounded-xl mt-1'>
                <select type="text" defaultValue={""} id='purpose' value={purpose} onChange={e => setPurpose(e.target.value)} className=' py-1 bg-[#474747] w-full rounded-xl outline-none border-none  cursor-pointer'>
                  <option value="" disabled>Choose purpose</option>
                  <option value="option 1">option 1</option>
                  <option value="option 2">option 2</option>
                  <option value="option 3">option 3</option>
                </select>
              </div>
            </div>

            <div>
              <h1 className='text-lg'>Access Dates</h1>
              <div className='flex items-center gap-4 mt-1'>
                <div className='bg-[#474747] px-4 py-1.5 rounded-xl cursor-pointer min-w-[150px] flex-grow' onClick={() => setActiveDate('startDate')}>
                  <h1>{dates.startDate ? moment(dates.startDate).format("DD/MM/YY") : 'Start Date'}</h1>
                </div>
                <span className='text-xl'>To</span>
                <div className={`${oneDay ? 'bg-[#141414]' : 'bg-[#474747]'} px-4 py-1.5 rounded-xl cursor-pointer min-w-[150px] flex-grow`} onClick={() => setActiveDate('endDate')}>
                  <h1>{dates.endDate ? moment(dates.endDate).format("DD/MM/YY") : 'End Date'}</h1>
                </div>
              </div>
              <div className='flex items-center gap-2 justify-end mt-3'>
                <h1>Allow for only one day</h1>
                <label className="switch">
                  <input type="checkbox" checked={oneDay} onChange={(e) => { setOneDay(e.target.checked) }} />
                  <span className="slider round"></span>
                </label>
              </div>
            </div>

            <div className='relative' style={{ display: employee?.name ? 'block' : 'none' }}>
              <div className='flex justify-between'>
                <h1 className='text-lg'>Assign temporary sector</h1>
                <span>{empSectors?.length || 0}/3</span>
              </div>
              <div className='bg-[#474747] rounded-xl p-4 h-40 my-1 relative'>
                <div className='flex flex-wrap gap-4'>
                  {
                    empSectors?.map((item, i) => <div key={i} className='rounded-md border-2 border-purple-400 bg-gray flex first-letter:items-center gap-3 py-1 px-2 h-max w-max'>
                      <span>{item.name}</span>
                      <svg onClick={() => removeEmpSector(i)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 cursor-pointer">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 12h-15" />
                      </svg>
                    </div>)
                  }

                  {
                    empSectors?.length < 3 ?
                      <div className='flex items-center border-2 border-blue-700 rounded-md h-max bg-gray relative' ref={ref}>
                        <input type="text" onFocus={() => setShow(true)} placeholder='Enter new sector' value={sector} onChange={(e) => setSector(e.target.value)} className='py-1 px-2 flex-grow w-40 bg-transparent outline-none border-none' />
                        <svg
                          xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 mr-2 cursor-pointer">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                        </svg>
                        {show ? <div className='bg-gray rounded-lg p-1 absolute top-9 left-0 w-full flex flex-col gap-1'>
                          {
                            userSectors?.filter(el => el?.name?.toLowerCase()?.includes(sector?.toLowerCase()))?.length
                              ?
                              userSectors?.filter(el => el?.name?.toLowerCase()?.includes(sector?.toLowerCase()))?.slice(0, 5)?.map((item, i) => <h1 key={i} className='text-sm py-1 px-2 rounded-md hover:bg-gray-800 cursor-pointer' onClick={() => addEmpSector(item)}>{item?.name}</h1>)
                              :
                              <h1 className='text-sm py-1 px-2'>No Sector Found</h1>
                          }
                        </div> : <></>}
                      </div> : <></>}
                </div>
              </div>
            </div>
            <div className='relative' style={{ display: employee?.name ? 'block' : 'none' }}>
              <h1 className='text-lg'>Assigned sector</h1>
              <div className='bg-[#474747] rounded-xl p-4 h-40 my-1 relative'>
                <div className='flex flex-wrap gap-4'>
                  {
                    employee?.sectors?.length ?
                      employee?.sectors?.map((item, i) => <div key={i} className='rounded-md border-2 border-purple-400 bg-gray flex first-letter:items-center gap-3 py-1 px-2 h-max w-max'>
                        <span>{item.sector}</span>
                      </div>)
                      :
                      <h1 className='text-center mt-8 w-full'>No sector assigned</h1>
                  }
                </div>
              </div>
            </div>
          </div>

          <div className='flex justify-between'>
            <button className='py-1.5 px-6 rounded-lg bg-orange outline-none border-none' onClick={() => onCancel()}>Cancel</button>
            <button className='py-1.5 px-6 rounded-lg bg-green outline-none border-none' disabled={
              requesting || !employee?.name || !purpose || !dates.startDate || !dates.endDate || !empSectors?.length
            } onClick={submitRequest}>Assign Access</button>
          </div>
        </form>
      </section>
      {
        success ?
          <SuccessModal onClose={onCancel} message={"Created Successfully!"} />
          : <></>
      }
    </>
  )
}