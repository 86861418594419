import React, { useEffect, useState } from 'react'
import SuccessModal from '../Reusable/SuccessModal'
import { useHistory } from 'react-router-dom'
import validator from 'validator'
import { useAddEmployees } from '../../hooks'
import { useQuery } from 'react-query'
import { BranchService } from '../../services/branch.service'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import MiniLoader from '../Reusable/MiniLoader'
import { useDispatch } from 'react-redux'
import { setEmployees } from '../../slices/userSlice'
import * as XLSX from 'xlsx';
import Papa from 'papaparse';
import toast from 'react-hot-toast'
import { FileUploader } from 'react-drag-drop-files'

function Employee({ type }) {
    console.log("type", type)
    const history = useHistory()
    const dispatch = useDispatch()
    const { branchName } = useParams()
    const [addEmployee, setAddEmployee] = useState(false)
    const [success, setSuccess] = useState(false)
    const [data, setData] = useState({
        name: '',
        email: '',
        employeeId: ''
    })
    const [search, setSearch] = useState('')
    const [jsonData, setJsonData] = useState(null);

    useEffect(() => {
        if (success) {
            setTimeout(() => {
                closePopUp();
            }, 3000);
        }
    }, [success]);

    const handleFileChange = (file) => {
        const reader = new FileReader();
        if (file) {
            reader.onload = (event) => {
                const data = new Uint8Array(event.target.result);
                let workbook;
                let convertedData;
                try {
                    // Try to parse as XLSX
                    workbook = XLSX.read(data, { type: 'array' });
                    const sheetName = workbook.SheetNames[0];
                    const sheet = workbook.Sheets[sheetName];
                    convertedData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
                } catch (error) {
                    // If it's not an XLSX, try parsing as CSV
                    Papa.parse(file, {
                        complete: (result) => {
                            convertedData = result.data;
                            processEmployeeData(convertedData);
                        },
                        header: true,
                        dynamicTyping: true,
                    });
                    return;
                }
                processEmployeeData(convertedData);
            };
            reader.readAsArrayBuffer(file);
        }
    };

    const processEmployeeData = (data) => {
        // Assuming the headers are present in the first row
        const headers = data[0].map((header) => header.trim().toLowerCase().replace(/\s/g, ''));
        const requiredHeaders = ['employeeid', 'employeename', 'email'];

        if (!requiredHeaders.every((header) => headers.includes(header))) {
            toast.error('Required fields are not present', { id: 'err' });
            return;
        }
        const formattedData = data.slice(1).map((row) => {
            const formattedRow = {};
            requiredHeaders.forEach((header, index) => {
                if (headers.includes(header)) {
                    formattedRow[header] = row[index].trim().toLowerCase();
                }
            });
            return formattedRow;
        });
        setJsonData(formattedData);
    };

    const { data: EmpRes, isLoading: loading } = useQuery('getEmployees', () => BranchService.getEmployees(type == 'employee' ? 'user' : type == 'security' ? 'security' : ''), {
        refetchOnWindowFocus: false,
        onError: (er) => console.log(er),
        onSuccess: res => {
            if (type == 'employee' && res?.data) {
                dispatch(setEmployees(res.data))
            }
        }
    })

    const handleChange = (e) => setData({ ...data, [e.target.id]: e.target.value });

    const { mutate, isLoading } = useAddEmployees(() => { setData({ name: '', email: '', employeeId: '' }); setJsonData(null); setAddEmployee(false); setSuccess(true) })

    const handleSubmit = (e, submitType) => {
        e.preventDefault();
        let employees = [];
        if (submitType == 'single')
            employees = [data];
        else if (submitType == 'bulk') {
            employees = jsonData?.map((el, i) => ({
                name: el['employeename'],
                email: el['email'],
                employeeId: el['employeeid']
            }))
        }
        else {
            return
        }

        mutate({
            role: type == 'employee' ? 'user' : type == 'security' ? 'security' : '',
            employees: employees
        })
    }

    const closePopUp = () => {
        setAddEmployee(false)
        setData({
            name: '',
            email: '',
            employeeId: ''
        });
        setJsonData(null)
    }

    if (loading) <MiniLoader />

    return (
        <section className='w-full p-5 2xl:px-10'>

            <div className='flex items-center justify-between pr-10'>
                <div className='flex items-center gap-2 bg-2 py-1 px-3 rounded-full flex-grow max-w-lg'>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                    </svg>
                    <input type="text" value={search} onChange={(e) => setSearch(e.target.value)} placeholder={type == 'security' ? 'Search for Security Personnel or emp id' : 'Search for employee or emp id'} className='placeholder:text-[#7E69FF] bg-transparent flex-grow outline-none border-none p-1' />
                </div>


                {type == 'employee' ? <button className='py-2 px-3 bg-5 rounded-lg' onClick={() => setAddEmployee(true)}>Add new Employee</button>
                    :
                    type == 'security'
                        ?
                        <button className='py-2 px-3 bg-5 rounded-lg' onClick={() => setAddEmployee(true)}>Add new Security</button>

                        : <></>}
            </div>
            {type == 'employee' ? <div className='w-full max-w-lg flex items-center gap-10 mt-4 justify-center'>
                <div className='flex items-center gap-2'>
                    <div className='w-5 h-5 rounded bg-green'></div>
                    <p className='font-extralight'>Most Visited</p>
                </div>
                <div className='flex items-center gap-2'>
                    <div className='w-5 h-5 rounded bg-yellow'></div>
                    <p className='font-extralight'>Not regularly visited</p>
                </div>
            </div>
                : type == 'security' ?
                    <div className='w-full max-w-lg flex items-center gap-10 mt-4 justify-center'>
                        <div className='flex items-center gap-2'>
                            <svg width="20" height="20" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M22.4502 0.294922H2.4502C1.91976 0.294922 1.41105 0.505635 1.03598 0.880708C0.660909 1.25578 0.450195 1.76449 0.450195 2.29492V9.64242C0.450195 20.8437 9.9277 24.5599 11.8252 25.1912C12.2305 25.329 12.6699 25.329 13.0752 25.1912C14.9752 24.5599 24.4502 20.8437 24.4502 9.64242V2.29492C24.4502 1.76449 24.2395 1.25578 23.8644 0.880708C23.4893 0.505635 22.9806 0.294922 22.4502 0.294922ZM22.4502 9.64367C22.4502 19.4462 14.1564 22.7212 12.4502 23.2912C10.7589 22.7274 2.4502 19.4549 2.4502 9.64367V2.29492H22.4502V9.64367ZM6.74269 13.0024C6.55505 12.8148 6.44964 12.5603 6.44964 12.2949C6.44964 12.0296 6.55505 11.7751 6.74269 11.5874C6.93034 11.3998 7.18483 11.2944 7.4502 11.2944C7.71556 11.2944 7.97006 11.3998 8.1577 11.5874L10.4502 13.8799L16.7427 7.58742C16.8356 7.49451 16.9459 7.42081 17.0673 7.37053C17.1887 7.32025 17.3188 7.29437 17.4502 7.29437C17.5816 7.29437 17.7117 7.32025 17.8331 7.37053C17.9545 7.42081 18.0648 7.49451 18.1577 7.58742C18.2506 7.68033 18.3243 7.79063 18.3746 7.91203C18.4249 8.03342 18.4508 8.16353 18.4508 8.29492C18.4508 8.42632 18.4249 8.55643 18.3746 8.67782C18.3243 8.79921 18.2506 8.90951 18.1577 9.00242L11.1577 16.0024C11.0648 16.0954 10.9545 16.1692 10.8331 16.2195C10.7117 16.2698 10.5816 16.2957 10.4502 16.2957C10.3188 16.2957 10.1887 16.2698 10.0673 16.2195C9.94586 16.1692 9.83557 16.0954 9.74269 16.0024L6.74269 13.0024Z" fill="#48B759" />
                            </svg>
                            <p className='font-extralight'>Most Self Entry</p>
                        </div>
                        <div className='flex items-center gap-2'>
                            <svg width="20" height="20" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M22.4502 0.0800781H2.4502C1.91976 0.0800781 1.41105 0.290792 1.03598 0.665864C0.660909 1.04094 0.450195 1.54965 0.450195 2.08008V9.42633C0.450195 20.6288 9.9277 24.3438 11.8252 24.9738C12.2303 25.1125 12.6701 25.1125 13.0752 24.9738C14.9752 24.3426 24.4502 20.6276 24.4502 9.42508V2.08008C24.4502 1.54965 24.2395 1.04094 23.8644 0.665864C23.4893 0.290792 22.9806 0.0800781 22.4502 0.0800781ZM22.4502 9.42883C22.4502 19.2313 14.1577 22.5063 12.4502 23.0763C10.7589 22.5138 2.4502 19.2413 2.4502 9.42883V2.08008H22.4502V9.42883ZM11.4502 12.0801V7.08008C11.4502 6.81486 11.5556 6.56051 11.7431 6.37297C11.9306 6.18544 12.185 6.08008 12.4502 6.08008C12.7154 6.08008 12.9698 6.18544 13.1573 6.37297C13.3448 6.56051 13.4502 6.81486 13.4502 7.08008V12.0801C13.4502 12.3453 13.3448 12.5996 13.1573 12.7872C12.9698 12.9747 12.7154 13.0801 12.4502 13.0801C12.185 13.0801 11.9306 12.9747 11.7431 12.7872C11.5556 12.5996 11.4502 12.3453 11.4502 12.0801ZM10.9502 16.5801C10.9502 16.2834 11.0382 15.9934 11.203 15.7467C11.3678 15.5 11.6021 15.3078 11.8762 15.1943C12.1503 15.0807 12.4519 15.051 12.7428 15.1089C13.0338 15.1668 13.3011 15.3096 13.5109 15.5194C13.7206 15.7292 13.8635 15.9965 13.9214 16.2874C13.9793 16.5784 13.9495 16.88 13.836 17.1541C13.7225 17.4282 13.5302 17.6625 13.2836 17.8273C13.0369 17.9921 12.7469 18.0801 12.4502 18.0801C12.0524 18.0801 11.6708 17.922 11.3895 17.6407C11.1082 17.3594 10.9502 16.9779 10.9502 16.5801Z" fill="#B7AC48" />
                            </svg>
                            <p className='font-extralight'>Most Assisted Entry</p>
                        </div>
                    </div>
                    :
                    <></>}

            <section className='py-5 flex flex-col gap-10'>
                {
                    EmpRes?.data?.filter(el => el?.name?.toLowerCase().includes(search?.toLowerCase()) || el?.employeeId?.includes(search))?.length
                        ?
                        EmpRes?.data?.filter(el => el?.name?.toLowerCase().includes(search?.toLowerCase()) || el?.employeeId?.includes(search))?.map((item, i) => (<EmployeeRow key={i} item={item} history={history} type={type} branchName={branchName} />))
                        :
                        <h1 className='text-3xl font-medium mt-10 text-center'>No matching <span>{type}</span> found</h1>
                }
            </section>

            {addEmployee ? <section className='fixed inset-0 grid place-items-center z-50'>
                <div className='fixed inset-0 bg-black bg-opacity-70' onClick={() => closePopUp()}></div>

                <div className='bg-gray p-5 xl:p-7 rounded-xl z-10 w-full max-w-3xl relative opacAnimation'>
                    <span className='cursor-pointer absolute top-5 right-5' onClick={() => closePopUp()}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-7 h-7">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </span>
                    <h1 className='text-3xl font-medium border-b-2 pb-1 max-w-max border-sky-400'>Add new Employee</h1>

                    <div className='w-full grid grid-cols-2 justify-center my-5'>

                        <form className='w-full max-w-sm flex flex-col gap-2 p-5 border-r-2 border-[#411EA6]'>
                            <h1 className='text-2xl'>Individual</h1>
                            <input type="text" id="name" value={data.name} onChange={handleChange} placeholder='Employee name' className='bg-[#474747] rounded-xl py-2 px-3 outline-none border-none w-full' />
                            <input type="text" id="employeeId" value={data.employeeId} onChange={handleChange} placeholder='Employee ID' className='bg-[#474747] rounded-xl py-2 px-3 outline-none border-none w-full' />
                            <input type="email" name="email" id="email" value={data.email} onChange={handleChange} placeholder='Employee email' className='bg-[#474747] rounded-xl py-2 px-3 outline-none border-none w-full' />
                            <div className='py-2 flex justify-end'>
                                <button onClick={e => handleSubmit(e, 'single')} disabled={data?.name?.length < 3 || data.employeeId?.length < 6 || !validator.isEmail(data.email) || isLoading} className='py-1 px-4 rounded-full bg-green'>Submit</button>
                            </div>
                        </form>

                        <div className='w-full p-5 flex flex-col gap-2'>
                            <h1 className='text-2xl'>Bulk Upload</h1>
                            <FileUploader
                                multiple={false}
                                name="uploadFile"
                                handleChange={handleFileChange}
                                types={['csv', 'xlxs']}
                                children={
                                    <div className='w-full px-5 py-5 h-[8.5rem] bg-[#474747] rounded-2xl font-light flex flex-col items-center justify-center gap-1 cursor-pointer'>
                                        {
                                            jsonData?.length ?
                                                <h1 className='text-xl'>
                                                    {jsonData?.length} Records Found
                                                </h1>
                                                :
                                                <>
                                                    <svg width="36" height="36" className='mb-1' viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M2.3125 30.1758V34.8633C2.3125 36.1065 2.80636 37.2988 3.68544 38.1778C4.56451 39.0569 5.7568 39.5508 7 39.5508H35.125C36.3682 39.5508 37.5605 39.0569 38.4396 38.1778C39.3186 37.2988 39.8125 36.1065 39.8125 34.8633V30.1758M11.6875 11.4258L21.0625 2.05078M21.0625 2.05078L30.4375 11.4258M21.0625 2.05078V30.1758" stroke="#787878" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                                                    </svg>
                                                    <p className='text-[#787878]'>Upload .xls or .csv File</p>
                                                    <p className='text-[#787878]'>Employee ID, Employee Name, Email</p>
                                                </>
                                        }
                                    </div>
                                }
                            />
                            <div className='py-2 flex justify-end'>
                                <button onClick={e => handleSubmit(e, 'bulk')} className='py-1 px-4 rounded-full bg-green'>Submit</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section> : <></>}

            {success ? <SuccessModal onClose={() => setSuccess(false)} message={"Created Successfully!"} description="Employee will appear in your portal after they sign up." /> : <></>}

        </section>
    )
}

export default Employee


const EmployeeRow = ({ history, type, item, branchName }) => {
    return (
        <div className='flex items-center gap-1 py-3'>
            <div className='relative'>
                <h1 className='text-xl mb-1 text-center min-w-[100px]'>{item.name}</h1>
                <div className='flex items-center gap-2 py-1 px-5 rounded-lg absolute -bottom-9 left-1/2 transform -translate-x-1/2 bg-5 cursor-pointer' onClick={() => history.push(`/branch/${branchName}/${type == 'security' ? 'security' : 'employees'}/${item.employeeId}`)}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 20.25h12m-7.5-3v3m3-3v3m-10.125-3h17.25c.621 0 1.125-.504 1.125-1.125V4.875c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125z" />
                    </svg>
                    <span>View</span>
                </div>
            </div>
            <svg width="57" height="76" viewBox="0 0 57 76" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2.90175 38.0276C33.8652 38.0286 25.1441 3.88118 54.0141 3.88304" stroke="#48B759" stroke-width="3" stroke-linecap="round" />
                <g style={{ mixBlendMode: 'hard-light' }}>
                    <path d="M2.90175 37.9998C33.8652 37.9987 25.1441 72.1462 54.0141 72.1443" stroke="#B7AC48" stroke-width="3" stroke-linecap="round" />
                </g>
            </svg>
            <div className='flex flex-col gap-9'>
                <div className='flex items-center gap-1'>
                    {
                        type == 'security'
                            ?
                            <>
                                <svg width="22" height="23" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M22.4502 0.294922H2.4502C1.91976 0.294922 1.41105 0.505635 1.03598 0.880708C0.660909 1.25578 0.450195 1.76449 0.450195 2.29492V9.64242C0.450195 20.8437 9.9277 24.5599 11.8252 25.1912C12.2305 25.329 12.6699 25.329 13.0752 25.1912C14.9752 24.5599 24.4502 20.8437 24.4502 9.64242V2.29492C24.4502 1.76449 24.2395 1.25578 23.8644 0.880708C23.4893 0.505635 22.9806 0.294922 22.4502 0.294922ZM22.4502 9.64367C22.4502 19.4462 14.1564 22.7212 12.4502 23.2912C10.7589 22.7274 2.4502 19.4549 2.4502 9.64367V2.29492H22.4502V9.64367ZM6.74269 13.0024C6.55505 12.8148 6.44964 12.5603 6.44964 12.2949C6.44964 12.0296 6.55505 11.7751 6.74269 11.5874C6.93034 11.3998 7.18483 11.2944 7.4502 11.2944C7.71556 11.2944 7.97006 11.3998 8.1577 11.5874L10.4502 13.8799L16.7427 7.58742C16.8356 7.49451 16.9459 7.42081 17.0673 7.37053C17.1887 7.32025 17.3188 7.29437 17.4502 7.29437C17.5816 7.29437 17.7117 7.32025 17.8331 7.37053C17.9545 7.42081 18.0648 7.49451 18.1577 7.58742C18.2506 7.68033 18.3243 7.79063 18.3746 7.91203C18.4249 8.03342 18.4508 8.16353 18.4508 8.29492C18.4508 8.42632 18.4249 8.55643 18.3746 8.67782C18.3243 8.79921 18.2506 8.90951 18.1577 9.00242L11.1577 16.0024C11.0648 16.0954 10.9545 16.1692 10.8331 16.2195C10.7117 16.2698 10.5816 16.2957 10.4502 16.2957C10.3188 16.2957 10.1887 16.2698 10.0673 16.2195C9.94586 16.1692 9.83557 16.0954 9.74269 16.0024L6.74269 13.0024Z" fill="#48B759" />
                                </svg>
                                <div className='h-1 w-5 rounded-full bg-green last:hidden'></div>
                            </> :
                            <></>
                    }

                    {
                        item?.sectors?.filter(el => el.sectorCount >= 5)?.length
                            ?
                            item?.sectors?.filter(el => el.sectorCount >= 5)?.map((el, i) => {
                                return (
                                    <React.Fragment key={i}>
                                        <div className='bg-green px-2 py-1 rounded-lg'>
                                            {el?.sector}
                                        </div>
                                        <div className='h-1 w-10 rounded-full bg-green last:hidden'></div>
                                    </React.Fragment>
                                )
                            })
                            :
                            <>
                                <div className='bg-transparent px-2 py-1 rounded-lg'>
                                    No Sector
                                </div>
                                <div className='h-1 w-10 rounded-full bg-green last:hidden'></div>
                            </>
                    }
                </div>
                <div className='flex items-center gap-1'>
                    {
                        type == 'security'
                            ?
                            <>
                                <svg width="22" height="23" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M22.4502 0.0800781H2.4502C1.91976 0.0800781 1.41105 0.290792 1.03598 0.665864C0.660909 1.04094 0.450195 1.54965 0.450195 2.08008V9.42633C0.450195 20.6288 9.9277 24.3438 11.8252 24.9738C12.2303 25.1125 12.6701 25.1125 13.0752 24.9738C14.9752 24.3426 24.4502 20.6276 24.4502 9.42508V2.08008C24.4502 1.54965 24.2395 1.04094 23.8644 0.665864C23.4893 0.290792 22.9806 0.0800781 22.4502 0.0800781ZM22.4502 9.42883C22.4502 19.2313 14.1577 22.5063 12.4502 23.0763C10.7589 22.5138 2.4502 19.2413 2.4502 9.42883V2.08008H22.4502V9.42883ZM11.4502 12.0801V7.08008C11.4502 6.81486 11.5556 6.56051 11.7431 6.37297C11.9306 6.18544 12.185 6.08008 12.4502 6.08008C12.7154 6.08008 12.9698 6.18544 13.1573 6.37297C13.3448 6.56051 13.4502 6.81486 13.4502 7.08008V12.0801C13.4502 12.3453 13.3448 12.5996 13.1573 12.7872C12.9698 12.9747 12.7154 13.0801 12.4502 13.0801C12.185 13.0801 11.9306 12.9747 11.7431 12.7872C11.5556 12.5996 11.4502 12.3453 11.4502 12.0801ZM10.9502 16.5801C10.9502 16.2834 11.0382 15.9934 11.203 15.7467C11.3678 15.5 11.6021 15.3078 11.8762 15.1943C12.1503 15.0807 12.4519 15.051 12.7428 15.1089C13.0338 15.1668 13.3011 15.3096 13.5109 15.5194C13.7206 15.7292 13.8635 15.9965 13.9214 16.2874C13.9793 16.5784 13.9495 16.88 13.836 17.1541C13.7225 17.4282 13.5302 17.6625 13.2836 17.8273C13.0369 17.9921 12.7469 18.0801 12.4502 18.0801C12.0524 18.0801 11.6708 17.922 11.3895 17.6407C11.1082 17.3594 10.9502 16.9779 10.9502 16.5801Z" fill="#B7AC48" />
                                </svg>
                                <div className='h-1 w-5 rounded-full bg-yellow last:hidden'></div>
                            </>
                            :
                            <></>
                    }

                    {
                        item?.sectors?.filter(el => el.sectorCount < 5)?.length
                            ?
                            item?.sectors?.filter(el => el.sectorCount < 5)?.map((el, i) => {
                                return (
                                    <React.Fragment key={i}>
                                        <div className='bg-yellow px-2 py-1 rounded-lg'>
                                            {el?.sector}
                                        </div>
                                        <div className='h-1 w-10 rounded-full bg-yellow last:hidden'></div>
                                    </React.Fragment>
                                )
                            })
                            :
                            <>
                                <div className='bg-transparent px-2 py-1 rounded-lg'>
                                    No Sector
                                </div>
                                <div className='h-1 w-10 rounded-full bg-green last:hidden'></div>
                            </>
                    }
                </div>
            </div>

        </div>
    )
}