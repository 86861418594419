import React from 'react'

function AdminCloud() {
    return (
        <>
            <svg className='w-96 fixed -top-10 -right-10' viewBox="0 0 358 280" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="218.512" cy="90.334" r="140.5" transform="rotate(180 218.512 90.334)" fill="#5675D7" stroke="#3D559E" stroke-width="19" />
                <circle cx="359.82" cy="129.443" r="140.5" transform="rotate(180 359.82 129.443)" fill="#5675D7" stroke="#3D559E" stroke-width="19" />
                <circle cx="150.617" cy="-20.5566" r="140.5" transform="rotate(180 150.617 -20.5566)" fill="#5675D7" stroke="#3D559E" stroke-width="19" />
                <circle cx="310.617" cy="-20.5566" r="140.5" transform="rotate(180 310.617 -20.5566)" fill="#5675D7" stroke="#3D559E" stroke-width="19" />
            </svg>
            <svg className='w-96 fixed -bottom-10 -left-10' viewBox="0 0 385 320" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="166.977" cy="190.061" r="140.5" fill="#5675D7" stroke="#3D559E" stroke-width="19" />
                <circle cx="25.668" cy="150.951" r="140.5" fill="#5675D7" stroke="#3D559E" stroke-width="19" />
                <circle cx="234.871" cy="300.951" r="140.5" fill="#5675D7" stroke="#3D559E" stroke-width="19" />
                <circle cx="74.8711" cy="300.951" r="140.5" fill="#5675D7" stroke="#3D559E" stroke-width="19" />
            </svg>

        </>
    )
}

export default AdminCloud