import React from 'react'

function MiniLoader() {
    return (
        <div className='w-full p-10 grid place-items-center'>
            <div className='w-20 h-20 rounded-full border-8 border-[#3E2F9A] border-b-transparent animate-spin'></div>
        </div>
    )
}

export default MiniLoader