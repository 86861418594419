import { Switch, Route } from 'react-router-dom';
import Home from './components/Dashboard/Home';
import AdminRoute from './AdminRoute';
import EmployeeInfo from './components/Admin/EmployeeInfo';
import Sectors from './components/Admin/Sectors';
import TemporaryAccess from './components/Admin/TemporaryAccess';
import AdminSettings from './components/Admin/Settings';
import BranchSettings from './components/Dashboard/Settings';
import Login from './components/Auth/Login';
import SignUp from './components/Auth/SignUp';
import ForgotPassword from './components/Auth/ForgotPassword';
import ResetPassword from './components/Auth/ResetPassword';
import VerifyOtp from './components/Auth/VerifyOtp';
import CompanySetup from './components/Auth/CompanySetup';
import CompanyLogo from './components/Auth/CompanyLogo';
import { useInitialLoad } from './hooks';
import Landing from './components/LandingPage/Landing';
import Loader from './components/Reusable/Loader';
import BranchRoute from './BranchRoute';
import AdminLogin from './components/Admin/Login'
import NotFound from './components/Reusable/NotFound';
import SubDomain from './components/LandingPage/SubDomain';
import DetailedSector from './components/Admin/DetailedSector';
import Branch from './components/LandingPage/Branch';
import Visitors from './components/Visitor/Visitors';
import Branches from './components/Admin/Branches';
import Admins from './components/Admin/Admins';

function Routes() {

    let { isLoading } = useInitialLoad()

    if (isLoading) {
        return <Loader />
    }

    return (
        <Switch>
            <Route exact path={"/"} component={() => (window.location.hostname?.split(".")[1] == 'admin' || window.location.hostname?.split(".")[0] == 'admin') ? <SubDomain /> : <Landing />} />
            {/* <Route exact path={"/admin/login"} component={AdminLogin} /> */}
            <Route exact path={"/branch"} component={Branch} />

            {/* <AdminRoute exact path={"/branches"} title={"Branches"} component={Branches} /> */}
            {/* <AdminRoute exact path={"/admins"} title={"Branch Admins"} component={Admins} /> */}

            {/* <Route exact path="/admin/login" component={() => <Login type={"admin"} />} /> */}
            <Route exact path="/admin/signup" component={CompanySetup} />
            <Route exact path="/add-logo" component={CompanyLogo} />

            <Route exact path="/branch/:branchName/login" type="branch" component={() => <Login type="branch" />} />
            <Route exact path="/branch/:branchName/signup" component={SignUp} />
            <Route exact path="/forgot-password" component={ForgotPassword} />
            <Route exact path="/reset-password" component={ResetPassword} />
            <Route exact path="/verify" component={VerifyOtp} />


            {/* <AdminRoute exact path="/employees" title="Employees" component={() => <Home type={"employee"} />} /> */}
            {/* <AdminRoute exact path="/employees/:employeeId" title="Employees" component={EmployeeInfo} /> */}
            {/* <AdminRoute exact path="/settings" title="Settings" component={AdminSettings} /> */}

            <BranchRoute exact path="/branch/:branchName/settings" title="Settings" component={BranchSettings} />
            <BranchRoute exact path="/branch/:branchName" title="Employees" component={() => <Home type={"employee"} />} />
            <BranchRoute exact path="/branch/:branchName/employees" title="Employees" component={() => <Home type={"employee"} />} />
            <BranchRoute exact path="/branch/:branchName/security" title="Security Personal" component={() => <Home type={"security"} />} />
            <BranchRoute exact path="/branch/:branchName/employees/:employeeId" title="Employees" component={EmployeeInfo} />
            <BranchRoute exact path="/branch/:branchName/security/:employeeId" title="Security" component={EmployeeInfo} />
            <BranchRoute exact path="/branch/:branchName/sectors" title="Sectors" component={Sectors} />
            <BranchRoute exact path="/branch/:branchName/sectors/:sectorName" title="Sectors" component={DetailedSector} />
            <BranchRoute exact path="/branch/:branchName/temporary-access" title={"Temporary Access"} component={TemporaryAccess} />
            <BranchRoute exact path="/branch/:branchName/visitors" title={"Visitors"} component={Visitors} />

            <Route exact path="*" component={NotFound} />
        </Switch>
    );
}

export default Routes;
