import moment from 'moment'
import React, { useState } from 'react'
import { useUpdateVisitorRequest } from '../../hooks';


const RequestCard = ({ item, activeType = 'mine', active, refetch }) => {
    const [reason, setReason] = useState('')
    const [reject, setReject] = useState()

    const { mutate: updateRequest, isLoading: updating } = useUpdateVisitorRequest(() => refetch());
    const handleRequest = (id, type) => {
        if (type == 2) {
            setReject({
                id
            })
        }
        else if (type == 1) {
            updateRequest({
                id, active: 1, status: 1
            });
        }
    }

    return (
        <>
            <div className={`w-[16rem] min-h-[250px] relative rounded-xl p-3 flex flex-col justify-between ${active == 'time' ? activeType == 'mine' ? item?.seen ? 'bg-[#561E16]' : 'bg-[#8B2E21]' : 'border-4 border-[#561E16]' : activeType == 'mine' ? item.seen ? 'bg-[#40275F]' : 'bg-7 ' : 'border-4 border-[#40275F]'}`}>
                <div>
                    <h1 className='font-medium text-lg'>{item.name}</h1>
                    <h1 className='font-light'>{item.company}</h1>

                    <h1 className='italic font-extralight'>{item.purpose}</h1>
                </div>

                <div className=' my-1 mx-auto'>
                    <p className='text-xs font-light'>Requested By:</p>
                    <p className=''>{item?.employee}</p>
                </div>
                <div className='bg-[#320071] rounded-full p-1 absolute bottom-[4.5rem] left-3 group cursor-pointer z-10'>
                    <svg width="24" height="24" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M22.8497 9.45679C17.3663 3.97534 8.1007 3.97534 2.6173 9.45679C0.664178 11.4099 0.476678 14.2898 2.15929 16.4597C2.30555 16.6472 2.49268 16.7989 2.70642 16.9031C2.92016 17.0074 3.15488 17.0615 3.39269 17.0613C3.5848 17.0614 3.77524 17.0256 3.95422 16.9558L8.73937 15.2595L8.76769 15.2488C9.00921 15.1522 9.22278 14.9968 9.38905 14.7968C9.55532 14.5968 9.66902 14.3584 9.71984 14.1033L10.296 11.2205C11.867 10.6774 13.5742 10.6736 15.1476 11.2097L15.754 14.115C15.8066 14.3673 15.9208 14.6027 16.0864 14.8002C16.252 14.9976 16.4639 15.1511 16.7032 15.2468L16.7316 15.2576L21.5167 16.9558C21.8293 17.0769 22.1726 17.0942 22.4958 17.0052C22.819 16.9163 23.105 16.7257 23.3116 16.4617C24.9903 14.2898 24.8019 11.4099 22.8497 9.45679ZM22.0763 15.4988L22.047 15.4871L17.2794 13.7947L16.673 10.8904C16.6179 10.6276 16.4961 10.3834 16.3192 10.1814C16.1424 9.97937 15.9164 9.82634 15.6632 9.73706C13.7527 9.08297 11.6783 9.08744 9.77062 9.74976C9.51544 9.84093 9.28836 9.9969 9.11167 10.2024C8.93498 10.4078 8.81476 10.6557 8.76281 10.9216L8.18664 13.8035L3.42004 15.4851C3.41027 15.4851 3.40344 15.4978 3.39367 15.5017C2.18957 13.948 2.31945 11.9636 3.72179 10.5623C6.1632 8.12085 9.44933 6.89722 12.7335 6.89722C16.0177 6.89722 19.3028 8.1189 21.7452 10.5623C23.1446 11.9636 23.2804 13.948 22.0763 15.4988ZM22.1075 20.1863C22.1075 20.3935 22.0252 20.5922 21.8787 20.7387C21.7322 20.8852 21.5335 20.9675 21.3263 20.9675H4.13976C3.93256 20.9675 3.73385 20.8852 3.58734 20.7387C3.44082 20.5922 3.35851 20.3935 3.35851 20.1863C3.35851 19.9791 3.44082 19.7804 3.58734 19.6339C3.73385 19.4873 3.93256 19.405 4.13976 19.405H21.3273C21.5345 19.405 21.7332 19.4873 21.8797 19.6339C22.0262 19.7804 22.1085 19.9791 22.1085 20.1863H22.1075Z" fill="white" />
                    </svg>
                    <div className='bg-[#272727] p-3 absolute left-10 bottom-0 rounded-lg min-w-max hidden group-hover:block'>
                        <h1>Contact Information</h1>
                        <p className='font-light text-sm mb-1 mt-3'>{item.code} {item?.mobile}</p>
                        <p className='font-extralight text-sm'>{item.email}</p>
                    </div>
                </div>
                <p className='text-center text-sm font-light'>{moment(item.accessDate).format("DD/MM/YY")}</p>
                <p className='text-center text-sm font-light'>Requested: {moment(item.createdAt).format("DD/MM/YY")}</p>
                <div className='mt-2'>
                    <div className='flex items-center justify-around gap-4'>
                        <button className='py-1 px-4 rounded-lg text-sm w-20 bg-green' disabled={updating} onClick={() => handleRequest(item.id, 1)}>Accept</button>
                        <button className='py-1 px-4 rounded-lg text-sm w-20 bg-red' disabled={updating} onClick={() => handleRequest(item.id, 2)}>Deny</button>
                    </div>
                    <p className='text-xs text-right mt-1'>Auto Reject: {moment(item?.createdAt).add(3, 'days').diff(moment(), 'days')} day(s)</p>
                </div>
            </div>
            {reject ? <div className='fixed inset-0 grid place-items-center z-40'>
                <div className='fixed inset-0 bg-black bg-opacity-70' onClick={() => { setReject(); setReason('') }}></div>
                <div className='w-full max-w-3xl rounded-lg bg-[#561E16] p-7 z-10 opacAnimation'>
                    <h1 className='text-3xl font-medium'>Reason for rejection</h1>
                    <div className='relative w-full max-w-2xl mx-auto rounded-xl bg-[#7E3126] mt-6 mb-10'>
                        <textarea value={reason} onChange={(e) => e.target?.value?.length < 101 ? setReason(e.target.value) : {}} className='w-full rounded-xl bg-transparent outline-none border-none resize-none p-3 h-32'></textarea>
                        <span className='absolute bottom-2 right-2 text-sm'>{reason?.length || 0}/100</span>
                    </div>

                    <div className='flex justify-between'>
                        <button onClick={() => { setReject(); setReason('') }} className='py-1.5 min-w-[160px] rounded-xl text-lg bg-[#399C43]'>
                            Cancel Rejection
                        </button>
                        <button disabled={reason?.length < 5} onClick={() => { updateRequest({ id: reject?.id, active: 2, status: 2, reason: reason?.slice(0, 100) }); setReject(); setReason('') }} className='py-1.5 min-w-[160px] rounded-xl text-lg bg-[#AB3D2E]'>
                            Reject Request
                        </button>
                    </div>
                </div>
            </div> : <></>}
        </>
    )
}

export default RequestCard