import React, { useEffect, useState } from 'react'
import SuccessModal from '../Reusable/SuccessModal2';
import { useUpdatePassword, useUpdateProfile } from '../../hooks';
import toast from 'react-hot-toast';
import { encryptPassword } from '../../services/constant';
import { useDispatch, useSelector } from 'react-redux';
import { selectUserInfo, setUserInfo } from '../../slices/userSlice';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

function BranchSettings() {
    const { branchName } = useParams();
    const userInfo = useSelector(selectUserInfo)
    const dispatch = useDispatch()
    const [copy1, setCopy1] = useState(false);
    const [copy2, setCopy2] = useState(false);
    const [copy3, setCopy3] = useState(false);
    const [copy4, setCopy4] = useState(false);
    const [edit, setEdit] = useState(false)
    const [reset, setReset] = useState(false)
    const [success, setSuccess] = useState(false)
    const [password, setPassword] = useState({
        old: '',
        new: '',
        confirm: ''
    })
    const [name, setName] = useState('')

    useEffect(() => {
        if (userInfo?.name) {
            setName(userInfo?.name)
        }
    }, [userInfo?.name])

    useEffect(() => {
        if (copy1 || copy2 || copy3 || copy4) {
            setTimeout(() => {
                setCopy1(false)
                setCopy2(false)
                setCopy3(false)
                setCopy4(false)
            }, 3000);
        }
    }, [copy1, copy2, copy3, copy4])


    let { mutate: resetPassword, isLoading: updating } = useUpdatePassword(() => {
        setReset(false);
        setSuccess({ message: 'Reset Successfully!' })
    })
    let { mutate: updateProfile } = useUpdateProfile()

    const handleChange = (e) => setPassword({ ...password, [e.target.id]: e.target.value })
    const handleSubmit = () => {
        if (password.confirm == password.new) {
            resetPassword({ oldPassword: encryptPassword(password.old), newPassword: encryptPassword(password.new) })
        }
        else {
            toast.error("Password doesn't matched", { id: 'error' })
        }
    }

    const cancelEdit = () => {
        setEdit(false)
        setName(userInfo?.name)
    }

    const logout = () => {
        dispatch(setUserInfo(null))
        localStorage.removeItem('aqqess_token');
        window.location.replace(branchName ? `/branch/${branchName}/login` : `/`)
    }

    return (
        <section className='p-5 w-full relative max-w-7xl mx-auto'>
            <button onClick={logout} className='absolute top-5 right-5 py-1.5 px-6 rounded-full bg-[#CB6747] flex items-center gap-2 justify-center font-medium text-lg'>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15m3 0l3-3m0 0l-3-3m3 3H9" />
                </svg>
                <span>Logout</span>
            </button>
            <div className='flex flex-col gap-3 items-start min-w-max'>
                <div className='w-full'>
                    <h1 className='text-2xl ml-1'>Name</h1>
                    <div className='flex items-center gap-4 w-full'>
                        <input type="text" value={name} disabled={!edit} onChange={(e) => setName(e.target.value)} className='w-full max-w-xs py-2 px-3 bg-gray rounded-xl outline-none border-none shadow-lg' />

                        {!edit ? <div onClick={() => setEdit(true)} className='p-2 rounded-lg cursor-pointer bg-purple'>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="black" className="w-6 h-6">
                                <path d="M21.731 2.269a2.625 2.625 0 00-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 000-3.712zM19.513 8.199l-3.712-3.712-12.15 12.15a5.25 5.25 0 00-1.32 2.214l-.8 2.685a.75.75 0 00.933.933l2.685-.8a5.25 5.25 0 002.214-1.32L19.513 8.2z" />
                            </svg>
                        </div>
                            :
                            <>
                                <button disabled={name?.length < 3} onClick={() => { setEdit(false); updateProfile({ name: name?.trim() }) }} className='p-2 rounded-lg cursor-pointer bg-[#57F567]'>
                                    <svg width="23" height="21" viewBox="0 0 23 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1.7793 11.6289L9.2793 19.1289L20.5293 2.25391" stroke="black" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                </button>
                                <button onClick={cancelEdit} className='p-2 rounded-lg cursor-pointer bg-[#CB6747]'>
                                    <svg width="23" height="21" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1.65625 17.1914L16.6562 2.19141M1.65625 2.19141L16.6562 17.1914" stroke="black" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                </button>
                            </>}
                    </div>
                </div>
                <div>
                    <h1 className='text-2xl'>Office Email</h1>
                    <p className='text-xl font-medium'>{userInfo?.email}</p>
                </div>
                <div>
                    <h1 className='text-2xl'>Employee ID</h1>
                    <p className='text-xl font-medium'>{userInfo?.employeeId}</p>
                </div>
                {userInfo?.branch ? <div>
                    <h1 className='text-2xl'>Branch</h1>
                    <p className='text-xl font-medium'>{userInfo?.branch}, {userInfo?.branchLocation}</p>
                </div> : ''}

                <button onClick={() => setReset(true)} className='py-1.5 px-4 rounded-full bg-[#CBBE47]'>
                    Reset Password
                </button>
            </div>
            {branchName ?
                <div className='flex items-center gap-4 justify-around w-full mt-1'>
                    <div>
                        <h1 className='text-5xl xl:text-7xl font-bold'>Your<br />Links</h1>
                    </div>
                    <div className='flex flex-col gap-3 w-full max-w-xl'>
                        <div className='max-w-xl'>
                            <h1 className='text-xl ml-1'>Branch Link</h1>
                            <div className='bg-gray rounded-xl flex items-center justify-between px-2 relative'>
                                {copy1 ? <span className='absolute -top-8 right-0'>Copied!!</span> : <></>}
                                <input type="text" value={encodeURI(`${userInfo?.subdomain}.admin.aqqess.me/branch/${userInfo?.branch}/login`)} disabled className='flex-grow text-lg p-1.5 outline-none border-none bg-transparent' />
                                <svg onClick={() => { navigator.clipboard.writeText(encodeURI(`${userInfo?.subdomain}.admin.aqqess.me/branch/${userInfo?.branch}/login`)); setCopy1(true) }} width="20" height="28" className='mx-2 cursor-pointer' viewBox="0 0 26 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M17.9166 4.50984C17.7221 3.82195 17.3084 3.21637 16.7382 2.78519C16.168 2.35402 15.4727 2.12083 14.7578 2.12109H10.3828C8.88073 2.12109 7.61198 3.13172 7.22406 4.50984M17.9166 4.50984C17.9968 4.79276 18.0391 5.09318 18.0391 5.40234C18.0391 5.69242 17.9238 5.97062 17.7187 6.17574C17.5136 6.38086 17.2354 6.49609 16.9453 6.49609H8.19531C7.90523 6.49609 7.62703 6.38086 7.42192 6.17574C7.2168 5.97062 7.10156 5.69242 7.10156 5.40234C7.10156 5.09318 7.14531 4.79276 7.22406 4.50984M17.9166 4.50984C18.8586 4.5813 19.7949 4.67026 20.7268 4.77818C22.3309 4.96484 23.5078 6.3488 23.5078 7.96464V27.2773C23.5078 28.1476 23.1621 28.9822 22.5468 29.5975C21.9314 30.2129 21.0968 30.5586 20.2266 30.5586H4.91406C4.04382 30.5586 3.20922 30.2129 2.59387 29.5975C1.97851 28.9822 1.63281 28.1476 1.63281 27.2773V7.96464C1.63281 6.3488 2.80823 4.96484 4.41385 4.77818C5.3487 4.66993 6.28562 4.58047 7.22406 4.50984" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                            </div>
                        </div>
                        <div className='max-w-xl'>
                            <h1 className='text-xl ml-1'>Employee Link</h1>
                            <div className='bg-gray rounded-xl flex items-center justify-between px-2 relative'>
                                {copy2 ? <span className='absolute -top-8 right-0'>Copied!!</span> : <></>}
                                <input type="text" value={encodeURI(`${userInfo?.subdomain}.emp.aqqess.me/branch/${userInfo?.branch}/login`)} disabled className='flex-grow text-lg p-1.5 outline-none border-none bg-transparent' />
                                <svg onClick={() => { navigator.clipboard.writeText(encodeURI(`${userInfo?.subdomain}.emp.aqqess.me/branch/${userInfo?.branch}/login`)); setCopy2(true) }} width="20" height="28" className='mx-2 cursor-pointer' viewBox="0 0 26 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M17.9166 4.50984C17.7221 3.82195 17.3084 3.21637 16.7382 2.78519C16.168 2.35402 15.4727 2.12083 14.7578 2.12109H10.3828C8.88073 2.12109 7.61198 3.13172 7.22406 4.50984M17.9166 4.50984C17.9968 4.79276 18.0391 5.09318 18.0391 5.40234C18.0391 5.69242 17.9238 5.97062 17.7187 6.17574C17.5136 6.38086 17.2354 6.49609 16.9453 6.49609H8.19531C7.90523 6.49609 7.62703 6.38086 7.42192 6.17574C7.2168 5.97062 7.10156 5.69242 7.10156 5.40234C7.10156 5.09318 7.14531 4.79276 7.22406 4.50984M17.9166 4.50984C18.8586 4.5813 19.7949 4.67026 20.7268 4.77818C22.3309 4.96484 23.5078 6.3488 23.5078 7.96464V27.2773C23.5078 28.1476 23.1621 28.9822 22.5468 29.5975C21.9314 30.2129 21.0968 30.5586 20.2266 30.5586H4.91406C4.04382 30.5586 3.20922 30.2129 2.59387 29.5975C1.97851 28.9822 1.63281 28.1476 1.63281 27.2773V7.96464C1.63281 6.3488 2.80823 4.96484 4.41385 4.77818C5.3487 4.66993 6.28562 4.58047 7.22406 4.50984" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                            </div>
                        </div>
                        <div className='max-w-xl'>
                            <h1 className='text-xl ml-1'>Scanning Device Link</h1>
                            <div className='bg-gray rounded-xl flex items-center justify-between px-2 relative'>
                                {copy3 ? <span className='absolute -top-8 right-0'>Copied!!</span> : <></>}
                                <input type="text" value={encodeURI(`${userInfo?.subdomain}.device.aqqess.me/branch/${userInfo?.branch}/login`)} disabled className='flex-grow text-lg p-1.5 outline-none border-none bg-transparent' />
                                <svg onClick={() => { navigator.clipboard.writeText(encodeURI(`${userInfo?.subdomain}.device.aqqess.me/branch/${userInfo?.branch}/login`)); setCopy3(true) }} width="20" height="28" className='mx-2 cursor-pointer' viewBox="0 0 26 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M17.9166 4.50984C17.7221 3.82195 17.3084 3.21637 16.7382 2.78519C16.168 2.35402 15.4727 2.12083 14.7578 2.12109H10.3828C8.88073 2.12109 7.61198 3.13172 7.22406 4.50984M17.9166 4.50984C17.9968 4.79276 18.0391 5.09318 18.0391 5.40234C18.0391 5.69242 17.9238 5.97062 17.7187 6.17574C17.5136 6.38086 17.2354 6.49609 16.9453 6.49609H8.19531C7.90523 6.49609 7.62703 6.38086 7.42192 6.17574C7.2168 5.97062 7.10156 5.69242 7.10156 5.40234C7.10156 5.09318 7.14531 4.79276 7.22406 4.50984M17.9166 4.50984C18.8586 4.5813 19.7949 4.67026 20.7268 4.77818C22.3309 4.96484 23.5078 6.3488 23.5078 7.96464V27.2773C23.5078 28.1476 23.1621 28.9822 22.5468 29.5975C21.9314 30.2129 21.0968 30.5586 20.2266 30.5586H4.91406C4.04382 30.5586 3.20922 30.2129 2.59387 29.5975C1.97851 28.9822 1.63281 28.1476 1.63281 27.2773V7.96464C1.63281 6.3488 2.80823 4.96484 4.41385 4.77818C5.3487 4.66993 6.28562 4.58047 7.22406 4.50984" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                            </div>
                        </div>
                        <div className='max-w-xl'>
                            <h1 className='text-xl ml-1'>Security Personnel Link</h1>
                            <div className='bg-gray rounded-xl flex items-center justify-between px-2 relative'>
                                {copy4 ? <span className='absolute -top-8 right-0'>Copied!!</span> : <></>}
                                <input type="text" value={encodeURI(`${userInfo?.subdomain}.security.aqqess.me`)} disabled className='flex-grow text-lg p-1.5 outline-none border-none bg-transparent' />
                                <svg onClick={() => { navigator.clipboard.writeText(encodeURI(`${userInfo?.subdomain}.security.aqqess.me`)); setCopy4(true) }} width="20" height="28" className='mx-2 cursor-pointer' viewBox="0 0 26 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M17.9166 4.50984C17.7221 3.82195 17.3084 3.21637 16.7382 2.78519C16.168 2.35402 15.4727 2.12083 14.7578 2.12109H10.3828C8.88073 2.12109 7.61198 3.13172 7.22406 4.50984M17.9166 4.50984C17.9968 4.79276 18.0391 5.09318 18.0391 5.40234C18.0391 5.69242 17.9238 5.97062 17.7187 6.17574C17.5136 6.38086 17.2354 6.49609 16.9453 6.49609H8.19531C7.90523 6.49609 7.62703 6.38086 7.42192 6.17574C7.2168 5.97062 7.10156 5.69242 7.10156 5.40234C7.10156 5.09318 7.14531 4.79276 7.22406 4.50984M17.9166 4.50984C18.8586 4.5813 19.7949 4.67026 20.7268 4.77818C22.3309 4.96484 23.5078 6.3488 23.5078 7.96464V27.2773C23.5078 28.1476 23.1621 28.9822 22.5468 29.5975C21.9314 30.2129 21.0968 30.5586 20.2266 30.5586H4.91406C4.04382 30.5586 3.20922 30.2129 2.59387 29.5975C1.97851 28.9822 1.63281 28.1476 1.63281 27.2773V7.96464C1.63281 6.3488 2.80823 4.96484 4.41385 4.77818C5.3487 4.66993 6.28562 4.58047 7.22406 4.50984" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                            </div>
                        </div>
                    </div>
                </div> : <></>}

            {reset ? <section className='fixed inset-0 grid place-items-center z-50'>
                <div className='fixed inset-0 bg-black bg-opacity-70' onClick={() => setReset(false)}></div>

                <div className='bg-gray p-5 xl:p-7 rounded-xl z-10 w-full max-w-2xl relative opacAnimation'>
                    <span className='cursor-pointer absolute top-5 right-5' onClick={() => setReset(false)}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-7 h-7">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </span>
                    <h1 className='text-3xl font-medium border-b-2 pb-1 max-w-max border-sky-400'>Reset Password</h1>
                    <div className='w-full max-w-lg flex flex-col items-center justify-center gap-5 p-5 mx-auto min-h-[300px]'>
                        <input type="text" id="old" value={password.old} onChange={handleChange} placeholder='Enter Old Password' className='bg-[#474747] rounded-xl py-2 px-3 outline-none border-none w-full' />
                        <input type="text" id="new" value={password.new} onChange={handleChange} placeholder='Enter New Password' className='bg-[#474747] rounded-xl py-2 px-3 outline-none border-none w-full' />
                        <input type="text" id="confirm" value={password.confirm} onChange={handleChange} placeholder='Confirm New Password' className='bg-[#474747] rounded-xl py-2 px-3 outline-none border-none w-full' />
                    </div>
                    <div className='flex items-center justify-between p-5'>
                        <button onClick={() => setReset(false)} className='py-1.5 px-6 rounded-xl text-lg bg-orange'>Cancel</button>
                        <button disabled={updating || password.old?.length < 6 || password.new?.length < 6 || password.confirm?.length < 6} onClick={() => handleSubmit()} className='py-1.5 px-6 rounded-xl text-lg bg-green'>Reset</button>
                    </div>
                </div>
            </section> : <></>}
            {success ? <SuccessModal onClose={() => setSuccess(false)} message={success?.message} /> : <></>}
        </section>
    )
}

export default BranchSettings