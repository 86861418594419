import moment from 'moment'
import React, { useState } from 'react'
import { useUpdateAccessRequest } from '../../hooks';


const RequestCard = ({ item, activeType, active, refetch }) => {
    console.log(item)
    const [reason, setReason] = useState('')
    const [reject, setReject] = useState()

    const { mutate: updateRequest, isLoading: updating } = useUpdateAccessRequest(() => refetch());
    const handleRequest = (id, type) => {
        if (type == 2) {
            setReject({
                id
            })
        }
        else if (type == 1) {
            updateRequest({
                id, active: 1, status: 1
            });
        }
    }

    return (
        <>
            <div className={`w-[16rem] min-h-[232px] rounded-xl p-3 flex flex-col justify-between ${active == 'time' ? activeType == 'mine' ? item.seen ? 'bg-[#561E16]' : 'bg-[#8B2E21]' : 'border-4 border-[#561E16]' : activeType == 'mine' ? item?.seen ? 'bg-[#40275F]' : 'bg-7 ' : 'border-4 border-[#40275F]'}`}>
                <div>
                    <h1 className='font-medium text-lg'>{item.name}</h1>
                    {
                        item?.requestType == 'branch'
                            ?
                            <div>
                                <h1 className='text-sm text-gray-300 font-extralight'>{item.employeeId}</h1>
                                <h1 className='line-clamp-1 font-light'>{item.branchName}</h1>
                            </div>
                            :
                            <h1 className='text-base font-light'>{item.sector}</h1>
                    }
                    <h1 className='italic font-extralight'>{item.purpose}</h1>
                </div>

                <div className='text-center mt-1'>
                    <p className='text-sm font-light'>{moment(item.dateFrom).format("DD/MM/YY")}{moment(item.dateTo).diff(item?.dateFrom, 'days') > 1 ? ` - ${moment(item.dateTo).subtract(1, 'days').format("DD/MM/YY")}` : ''}</p>
                    <p className='text-xs'>{moment(item.dateTo).diff(item?.dateFrom, 'days')} Day(s)</p>
                </div>
                <p className='text-center text-sm font-light'>Requested: {moment(item.createdAt).format("DD/MM/YY")}</p>
                <div className='mt-1'>
                    <div className='flex items-center justify-around gap-4'>
                        <button className='py-1 px-4 rounded-lg text-sm w-20 bg-green' disabled={updating} onClick={() => handleRequest(item.id, 1)}>Accept</button>
                        <button className='py-1 px-4 rounded-lg text-sm w-20 bg-red' disabled={updating} onClick={() => handleRequest(item.id, 2)}>Deny</button>
                    </div>

                    <p className='text-xs text-right mt-1'>Auto Reject: {Math.min(moment(item?.createdAt).add(3, 'days').diff(moment(), 'days'), moment(item?.dateTo).diff(moment(), 'days') ) } day(s)</p>
                </div>
            </div>
            {reject ? <div className='fixed inset-0 grid place-items-center z-40'>
                <div className='fixed inset-0 bg-black bg-opacity-70' onClick={() => { setReject(); setReason('') }}></div>
                <div className='w-full max-w-3xl rounded-lg bg-[#561E16] p-7 z-10 opacAnimation'>
                    <h1 className='text-3xl font-medium'>Reason for rejection</h1>
                    <div className='relative w-full max-w-2xl mx-auto rounded-xl bg-[#7E3126] mt-6 mb-10'>
                        <textarea value={reason} onChange={(e) => e.target?.value?.length < 101 ? setReason(e.target.value) : {}} className='w-full rounded-xl bg-transparent outline-none border-none resize-none p-3 h-32'></textarea>
                        <span className='absolute bottom-2 right-2 text-sm'>{reason?.length || 0}/100</span>
                    </div>

                    <div className='flex justify-between'>
                        <button onClick={() => { setReject(); setReason('') }} className='py-1.5 min-w-[160px] rounded-xl text-lg bg-[#399C43]'>
                            Cancel Rejection
                        </button>
                        <button disabled={reason?.length < 5} onClick={() => { updateRequest({ id: reject?.id, active: 2, status: 2, reason: reason?.slice(0, 100) }); setReject(); setReason('') }} className='py-1.5 min-w-[160px] rounded-xl text-lg bg-[#AB3D2E]'>
                            Reject Request
                        </button>
                    </div>
                </div>
            </div> : <></>}
        </>
    )
}

export default RequestCard