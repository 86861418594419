import React from 'react'
import Threadality from '../../assets/threadality.svg'
import BranchCloud from './BranchCloud'
import { useSelector } from 'react-redux'
import { selectCompany } from '../../slices/userSlice'
import Logo from '../../assets/logo.svg'

function Auth({ children }) {
    const company = useSelector(selectCompany)

    return (
        <section className='h-screen w-full flex bg-[#161F3B]'>
            <div className='w-1/2 h-screen bg-[#14121F] grid place-items-center relative'>
                {company ?
                    <img src={company?.logo || Threadality} className='w-full max-w-xs 2xl:max-w-sm' alt="" />
                    : <div className='flex items-end justify-center gap-2 cursor-pointer'>
                        <img src={Logo} className='w-40' alt="" />
                        <h1 className='text-4xl 2xl:text-5xl font-medium transform -translate-y-4'>Aqqess</h1>
                    </div>
                }
                <BranchCloud />
            </div>

            <div className='flex-grow flex flex-col items-center justify-center relative p-5'>
                {children}
            </div>
        </section>
    )
}

export default Auth