import moment from 'moment'
import React from 'react'
import { week } from '../../services/constant'

function RequestList({ item, i, specific = false, employee }) {

    return (
        <>
            <tr key={i} className='text-sm'>
                <td className='p-2 pb-2.5 bg-gray rounded-l-lg'>
                    <p>{moment(item?.createdAt).format("DD/MM/YY")}</p>
                    <p>{week[moment(item?.createdAt).day()]}</p>
                </td>
                {specific ? <></> : <td className='p-2 pb-2.5 bg-gray'>{item?.name}</td>}
                <td className='p-2 pb-2.5 bg-gray'>{item.sector || item.branchName}</td>
                <td className='p-2 pb-2.5 bg-gray'>
                    <div className='flex items-center justify-center min-w-max gap-1'>
                        <h1>{moment(item.dateFrom).format("DD/MM/YY")}</h1>
                        <svg width="30" height="6" viewBox="0 0 30 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.833333 3.11328C0.833333 4.58604 2.02724 5.77995 3.5 5.77995C4.97276 5.77995 6.16667 4.58604 6.16667 3.11328C6.16667 1.64052 4.97276 0.446615 3.5 0.446615C2.02724 0.446615 0.833333 1.64052 0.833333 3.11328ZM29.3319 3.61328C29.608 3.61328 29.8319 3.38942 29.8319 3.11328C29.8319 2.83714 29.608 2.61328 29.3319 2.61328V3.61328ZM3.5 3.61328H29.3319V2.61328H3.5V3.61328Z" fill="white" />
                        </svg>
                        <p className='text-xs'>{moment(item.dateTo).diff(item?.dateFrom, 'days')} Days</p>
                        <svg width="30" height="6" viewBox="0 0 30 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.33203 2.61328C1.05589 2.61328 0.832031 2.83714 0.832031 3.11328C0.832031 3.38942 1.05589 3.61328 1.33203 3.61328V2.61328ZM24.4972 3.11328C24.4972 4.58604 25.6911 5.77995 27.1639 5.77995C28.6367 5.77995 29.8306 4.58604 29.8306 3.11328C29.8306 1.64052 28.6367 0.446615 27.1639 0.446615C25.6911 0.446615 24.4972 1.64052 24.4972 3.11328ZM1.33203 3.61328H27.1639V2.61328H1.33203V3.61328Z" fill="white" />
                        </svg>
                        <h1>{moment(item.dateTo).subtract(1, 'days').format("DD/MM/YY")}</h1>
                    </div>
                </td>
                <td className='p-2 pb-2.5 bg-gray'>
                    <p>{item.purpose}</p>
                </td>
                {employee ? <td className='p-2 pb-2.5 bg-gray capitalize'>
                    <p>{item.requestType}</p>
                </td> : <></>}
                <td className='p-2 pb-2.5 bg-gray rounded-r-lg'>
                    <p className=''>{item?.status == 1 ? <span className='text-green'>Accepted</span> : item?.status == 2 ? item?.rejectedBy ? <span className='text-red'>Rejected<br /><span className='italic text-xs text-red text-right font-extralight ml-5'>Admin</span></span> : <span className='text-red'>Req. timed out</span> : ''}</p>
                </td>
            </tr>
            {
                item?.status == 2 && item?.rejectedBy
                    ?
                    <tr className='transform -translate-y-2.5'>
                        <td colSpan={6} className='text-center text-sm text-[#FF6767] font-extralight pt-1 pb-2 bg-gray rounded-br-lg rounded-bl-lg border-t border-[#FF4B4B]'>{item?.reason}</td>
                    </tr>
                    :
                    <></>
            }
        </>
    )
}

export default RequestList