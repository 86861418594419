import React from 'react'
import SuccessImage from '../../assets/success.svg'

function SuccessModal({ onClose, message, description }) {
    return (
        <section className='fixed inset-0 grid place-items-center z-50'>
            <div className='fixed inset-0 bg-black bg-opacity-70' onClick={() => onClose()}></div>

            <div className='bg-gray p-10 rounded-xl z-10 flex flex-col items-center justify-center gap-6 w-full max-w-2xl relative opacAnimation min-h-[400px]'>
                <img src={SuccessImage} className='h-40' alt="" />
                <div>
                    <h1 className='text-3xl font-medium text-center'>{message}</h1>
                    <p className='text-lg font-light text-center'>{description}</p>
                </div>
            </div>
        </section>
    )
}

export default SuccessModal