import React, { useEffect, useState } from 'react'
import Auth from './Auth'
import Logo from '../../assets/logo.svg'
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min'
import validator from 'validator'
import { toast } from 'react-hot-toast'
import { useRegister } from '../../hooks'
import { useQuery } from 'react-query'
import { BranchService } from '../../services/branch.service'
import useDebounce, { encryptPassword } from '../../services/constant'

function SignUp() {
    const { branchName } = useParams()
    const history = useHistory()
    const [error, setError] = useState(false)
    const [show, setShow] = useState(false)
    const [showC, setShowC] = useState(false)
    const [data, setData] = useState({
        email: '',
        employeeId: '',
        branch: '',
        name: '',
        password: '',
        cPassword: '',
        branchId: ''
    })

    let { refetch } = useQuery('validateUserInfo', () => BranchService.validate({ email: data.email, employeeId: data.employeeId }), {
        enabled: false, refetchOnWindowFocus: false, onSuccess: (res) => {
            if (res.success == true) {
                if (res.data) {
                    setData({ ...data, branch: res.data.branch, name: res.data.name, branchId: res.data.branchId })
                }
            } else {
                if (res?.message) {
                    setError(true)
                    toast.error(res?.message, { id: 'error' })
                }
            }
        }, onError: (err) => console.log("err -> ", err)
    })

    let debouncedEmail = useDebounce(data.email, 400);
    let debouncedEmpId = useDebounce(data.employeeId, 400);

    useEffect(() => {
        if (validator.isEmail(debouncedEmail?.trim()) && debouncedEmpId?.length > 5) {
            refetch();
        }
    }, [debouncedEmail, debouncedEmpId])

    const handleChange = (e) => { setData({ ...data, [e.target.id]: e.target.value }); setError(false) }

    let { mutate, isLoading } = useRegister(() => history.push('/verify', { email: data.email, register: true, type: 'branch' }));

    const handleSubmit = (e) => {
        e.preventDefault();
        let { cPassword, password, email, employeeId } = data

        if (password !== cPassword) {
            return toast.error("Password doesn't match!")
        }
        else {
            mutate({
                email, employeeId: employeeId?.toLowerCase(), password: encryptPassword(password)
            })
        }
    }

    return (
        <Auth>
            <form className='w-full lg:max-w-md 2xl:max-w-lg flex flex-col gap-3 pt-20'>
                <h1 className='text-3xl 2xl:text-4xl font-medium'>Hello! Nice to see you!</h1>
                <h1 className='text-2xl 2xl:text-3xl font-extralight -mt-2'>Lets Get started...</h1>
                <input type="email" id='email' value={data.email} onChange={handleChange} minLength={5} placeholder='Enter your email' className='py-2 px-4 rounded-xl bg-[#0A0817] text-[#6993FF] w-full text-lg outline-none border-none' />
                <input type="text" id='employeeId' value={data.employeeId} onChange={handleChange} placeholder='Employee ID' className='py-2 px-4 rounded-xl bg-[#0A0817] text-[#6993FF] w-full text-lg outline-none border-none' />
                <div className='bg-[#9874FF] w-full rounded-full h-1'></div>
                <div className='flex flex-col gap-3 relative'>
                    {data.name && data.branch ? <></> : <div className='absolute bg-[#161F3B] bg-opacity-70 inset-0 z-10'></div>}
                    {error ? <div className='absolute bg-[#FF0000] bg-opacity-[0.15] border-2 border-[#FF0000] inset-0 z-10 transform scale-[1.03] rounded'></div> : <></>}
                    <input type="text" value={data.branch} disabled placeholder='Assigned Branch' className='py-2 px-4 rounded-xl bg-[#0A0817] text-[#6993FF] w-full text-lg outline-none border-none' />
                    <input type="text" value={data.name} disabled placeholder='Your Name' className='py-2 px-4 rounded-xl bg-[#0A0817] text-[#6993FF] w-full text-lg outline-none border-none' />
                    <div className='bg-[#74BCFF] w-full rounded-full h-1'></div>

                    <div className='relative'>
                        <input type={show ? 'text' : 'password'} id='password' value={data.password} onChange={handleChange} minLength={5} placeholder='Enter your password' className='py-2 px-4 rounded-xl bg-[#0A0817] text-[#6993FF] w-full text-lg outline-none border-none' />
                        {!show ? <svg onClick={() => setShow(true)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 absolute top-2 right-3 cursor-pointer">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                            <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                        </svg>
                            : <svg onClick={() => setShow(false)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 absolute top-2 right-3 cursor-pointer">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88" />
                            </svg>}
                    </div>
                    <div className='relative'>
                        <input type={showC ? 'text' : 'password'} id='cPassword' value={data.cPassword} onChange={handleChange} minLength={5} placeholder='Confirm Password' className='py-2 px-4 rounded-xl bg-[#0A0817] text-[#6993FF] w-full text-lg outline-none border-none' />
                        {!showC ? <svg onClick={() => setShowC(true)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 absolute top-2 right-3 cursor-pointer">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                            <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                        </svg>
                            : <svg onClick={() => setShowC(false)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 absolute top-2 right-3 cursor-pointer">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88" />
                            </svg>}
                    </div>
                </div>

                <div className='flex flex-col justify-end items-end gap-1 pt-4'>
                    <button onClick={handleSubmit} disabled={
                        !validator.isEmail(data.email) ||
                        data.employeeId?.length < 6 ||
                        data.password?.length < 6 ||
                        data.cPassword?.length < 6
                        || isLoading
                    } className='bg-8 py-1.5 px-6 rounded-xl text-lg min-w-[120px] flex items-center justify-center'>
                        {
                            isLoading
                                ?
                                <div className='w-7 h-7 rounded-full border-2 border-white border-b-transparent animate-spin'></div>
                                : <span>SignUp</span>
                        }
                    </button>
                    <p>Already created an account? <span className='hover:text-yellow-300 cursor-pointer' onClick={() => history.push(`/branch/${branchName}/login`)}>Login</span></p>
                </div>
            </form>

            <div className='flex items-center justify-center gap-2 py-5 cursor-pointer absolute top-2 right-10'>
                <img src={Logo} alt="" />
                <h1 className='text-3xl font-medium'>Aqqess</h1>
            </div>

        </Auth>
    )
}

export default SignUp