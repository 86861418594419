import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    userInfo: null,
    company: null,
    userSectors: [],
    securities: [],
    employees:[],
    branches: []
}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUserInfo: (state, action) => {
            state.userInfo = action.payload || null
        },
        setCompany: (state, action) => {
            state.company = action.payload || null
        },
        setUserSectors: (state, action) => {
            state.userSectors = action.payload || []
        },
        setSecurities: (state, action) => {
            state.securities = action.payload || [];
        },
        setEmployees: (state, action) => {
            state.employees = action.payload || [];
        },
        setAdminBranches: (state, action) => {
            state.branches = action.payload || [];
        }
    },
})

// Action creators are generated for each case reducer function
export const { setUserInfo, setCompany, setUserSectors, setSecurities , setEmployees, setAdminBranches} = userSlice.actions

export const selectUserInfo = (state) => state.user.userInfo
export const selectUserSectors = (state) => state.user.userSectors
export const selectCompany = (state) => state.user.company
export const selectSecurities = (state) => state.user.securities
export const selectEmployees = (state) => state.user.employees
export const selectBranches = (state) => state.user.branches

export default userSlice.reducer