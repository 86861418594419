import React from 'react'
import Employee from '../Admin/Employee'

function Home({ type }) {

    return (
        <Employee type={type} />
    )
}

export default Home