import React from 'react'
import { useQuery } from 'react-query'
import { BranchService } from '../../services/branch.service'

function QuickInsight({ employeeId, type }) {

    const { data: Insight, isFetching } = useQuery('eqInsight', () => type == 'employee' ? BranchService.eqInsight(employeeId) : type == 'sector' ? BranchService.sqInsight() : {}, {
        retry: false,
        refetchOnWindowFocus: false,
    })

    return (
        <div className='w-full max-w-xs min-w-[20rem] p-4 bg-gray rounded-xl mt-6 sticky top-[110px]'>
            {isFetching ? <div className='absolute bg-1 inset-0'></div> : <></>}
            <h1 className='text-purple text-xl'>Quick Insights</h1>
            <div className='text-center py-2'>
                <h1 className='text-lg font-medium leading-tight'>{Insight?.data?.total || 0}</h1>
                <p>No. of Entry</p>
            </div>

            <div className='grid grid-cols-2 gap-x-10 gap-y-2 text-right'>
                <div>
                    <h1 className='text-lg font-medium leading-tight'>{Insight?.data?.self || 0}</h1>
                    <p className='min-w-max'>Self attempts</p>
                </div>
                <div>
                    <h1 className='text-lg font-medium leading-tight'>{Insight?.data?.assisted || 0}</h1>
                    <p className='min-w-max'>Assisted Attempts</p>
                </div>
                <div>
                    <h1 className='text-lg font-medium leading-tight text-red-300'>{Insight?.data?.denied || 0}</h1>
                    <p className='min-w-max text-red-300'>Denied attempts</p>
                </div>
            </div>

            <div>
                <div className='w-48 mx-auto pt-2'>
                    <p className='text-right text-sm'>{Insight?.data?.mostVisited ? Insight?.data?.mostVisited[1] : 0} Times</p>
                    <div className='border-2 rounded-md border-purple-400 text-sm py-1 px-2'>{Insight?.data?.mostVisited ? Insight?.data?.mostVisited[0] : 'No Sector'}</div>
                    <p>Most Visited Sector</p>
                </div>
                <div className='w-48 mx-auto pt-2'>
                    <p className='text-right text-sm'>{Insight?.data?.leastVisited ? Insight?.data?.leastVisited[1] : 0} Times</p>
                    <div className='border-2 rounded-md border-purple-400 text-sm py-1 px-2'>{Insight?.data?.leastVisited ? Insight?.data?.leastVisited[0] : 'No Sector'}</div>
                    <p>Least Visited Sector</p>
                </div>
                <div className='w-48 mx-auto pt-2'>
                    <p className='text-right text-sm'>{Insight?.data?.deniedSector ? Insight?.data?.deniedSector[1] : 0} Times</p>
                    <div className='border-2 rounded-md border-red-400 text-sm py-1 px-2'>{Insight?.data?.deniedSector ? Insight?.data?.deniedSector[0] : 'No Sector'}</div>
                    <p>Most Denied Sector</p>
                </div>
            </div>
        </div>
    )
}

export default QuickInsight