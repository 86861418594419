import moment from 'moment/moment';
import React, { useState } from 'react'
import { BranchService } from '../../services/branch.service';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import RequestCard from '../Branch/RequestCard';
import RequestList from '../Branch/RequestList';
import MiniLoader from '../Reusable/MiniLoader';


function daysInMonth(month, year) {
  return new Date(year, month, 0).getDate();
}

const today = new Date()
const month = today.getMonth();
const year = today.getFullYear();
const YY = today.getFullYear() % 2000;

const options = [
  {
    id: 1,
    title: 'requests'
  },
  {
    id: 2,
    title: 'past access'
  },
]

function TemporaryAccess() {
  const { employeeId } = useParams()
  const [active, setActive] = useState('requests')
  const { data: RequestRes, isLoading, refetch } = useQuery('accessRequest', () => BranchService.accessRequest(employeeId), {
    retry: false,
    // refetchOnMount: false,
    refetchOnWindowFocus: false,
    onError: res => console.log(res)
  })
  const { data: PastRequestRes } = useQuery('pastRequest', () => BranchService.pastRequest(employeeId), {
    retry: false,
    // refetchOnMount: false,
    refetchOnWindowFocus: false,
    onError: res => console.log(res)
  })

  return (
    <section className='p-4'>
      <div className='flex items-center justify-start gap-10 py-3'>
        {options?.map((item) => <div key={item.id} className='max-w-max cursor-pointer' onClick={() => setActive(item.title)}>
          <h1 className='text-xl pb-1 capitalize'>{item.title}</h1>
          {active == item.title ? <div className='h-0.5 rounded-full bg-7'></div> : <div className='h-0.5'></div>}
        </div>)}
      </div>
      {
        isLoading ?
          <MiniLoader />
          :
          active == 'past access'
            ?
            <>
              <p className='text-lg italic'>Showing latest 100 requests</p>
              <table className='table-auto w-full' style={{ borderCollapse: 'separate', borderSpacing: '0 4px' }}>
                <thead className='rounded-lg'>
                  <tr>
                    <td className='px-2 py-3 bg-4 rounded-tl-lg'>Req. Date</td>
                    <td className='px-2 py-3 bg-4'>Location</td>
                    <td className='px-2 py-3 bg-4'>
                      <div className='text-center'>
                        <h1>Date Requested</h1>
                      </div>
                    </td>
                    <td className='px-2 py-3 bg-4'>Purpose</td>
                    <td className='px-2 py-3 bg-4'>Request Type</td>
                    <td className='px-2 py-3 bg-4 rounded-tr-lg'>Status</td>
                  </tr>
                </thead>
                <tbody className='relative'>
                  {PastRequestRes?.data?.length ?
                    PastRequestRes?.data?.map((item, i) => <RequestList employee={true} key={i} i={i} item={item} specific={true} />)
                    :
                    <h1 className='text-3xl font-medium min-w-max text-center absolute w-full mt-10'>No Request Available</h1>
                  }
                </tbody>
              </table>
            </>
            :
            <>
              <div className='flex flex-wrap gap-5 py-2'>
                {
                  RequestRes?.data?.length ?
                    RequestRes?.data?.map((item, i) => <RequestCard key={i} item={item} activeType={'mine'} active={null} refetch={refetch} />)
                    :
                    <h1 className='text-3xl font-medium min-w-max text-center w-full mt-10'>No Request Available</h1>
                }
              </div>
            </>
      }
    </section>
  )
}

export default TemporaryAccess